import { GET_ORGANIZATION_DETAILS, GET_ORGANIZATION_DETAILS_SUCCESS, GET_ORGANIZATION_DETAILS_FAILURE } from "../constants";

export const fetchOrganizationDetails = () => ({
    type: GET_ORGANIZATION_DETAILS
})

export const fetchOrganizationDetailsSuccess = (data) => ({
    type: GET_ORGANIZATION_DETAILS_SUCCESS,
    payload: {data: data}
})

export const fetchOrganizationDetailsFailure = (error) => ({
    type: GET_ORGANIZATION_DETAILS_FAILURE,
    payload: {error: error}
})