import React from 'react';
import './BorderedCard.scss';
import Card from '../Card/Card';
const BorderedCard = props => {
    const borderWidth = 5;
    const {left, right, top, bottom, borderColor, title} = props;
    const styles = {
        borderTop: top ? `${borderWidth}px solid ${borderColor}` : 'none',
        borderRight: right ? `${borderWidth}px solid ${borderColor}` : 'none',
        borderBottom: bottom ? `${borderWidth}px solid ${borderColor}` : 'none',
        borderLeft: left ? `${borderWidth}px solid ${borderColor}` : 'none',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.07)',
    }
    return (
        <Card style={styles} className={props.className}>
            <h5 className='text-bolder' style={{color: borderColor}}>{title}</h5>
            {props.children}
        </Card>
    )
}

export default BorderedCard;