import React, { useEffect } from 'react';
import './Blogs.scss';
import { Container, Row, Col } from 'react-bootstrap';
import NewsItem from '../../Components/NewsItem/NewsItem';
import Section from '../../Components/Section/Section';
import Stories from '../../Components/Stories/Stories';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNews } from '../../redux/actions/News';
import { arrayChunk } from '../../utils/arrayChunk';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
const NewsArticle = Array(4).fill(null);
const monologs = Array(3).fill(null);

const Blogs = props => {
    const dispatch = useDispatch();
    const News = useSelector(state => state.News);
    const popNews = News.news ? arrayChunk(News.news, 4) : false;
    useEffect(() => {
        dispatch(fetchNews('status=true&_limit=20'))

    }, [0])
    return (
        <React.Fragment>

            <Container>

                <Section title="News & Stories">
                    {!News.fetching && popNews && popNews.map((bulkNews, index) => {
                        return (
                            <Row className="align-items-center mb-5">
                                <Col lg={5}>

                                    <NewsItem data={bulkNews[0]} size="big" />

                                </Col>
                                <Col lg={7} className="pl-3 pl-lg-5">
                                    {bulkNews.length > 1 && bulkNews.slice(1, 4).map((news, index) => {
                                        return <NewsItem key={index} data={news} />
                                    })}
                                </Col>
                            </Row>
                        )
                    })}

                    <Row className="my-5 justify-content-center">
                        {News.fetching &&
                            <Loading />
                        }

                        {!News.fetching && News.news.length === 0 &&
                            <EmptyHolder text="Courses are Not Available" />
                        }
                    </Row>

                </Section>
            </Container>

        </React.Fragment>
    )
}

export default Blogs;