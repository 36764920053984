import React, { useEffect, useState } from 'react';
import './Alumni.scss';
import { Container, Row, Col, Modal } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchGalleries } from '../../redux/actions/Galleries';
// import ImageLoader from 'react-imageloader';
// import { getImageUrl } from '../../utils/ImageUrl';
// import Gallery from 'react-grid-gallery';
import { takeAlumnis } from '../../api/api';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import Loading from '../../Components/Loading/Loading';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import TeamItem from '../../Components/TeamItem/TeamItem';
import StudentQuote from '../../Components/StudentQuote/StudentQuote';



const Alumni = props => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [err, setErr] = useState(false);
    const getData = async (data) => {
        setLoading(true);
        takeAlumnis().then((data) => {
            setData(data.data);
            setLoading(false);
            setErr(false);
        }).
            catch(err => {
                console.log(err)
                setLoading(false);
                setErr(err);
                setData(false)
            });
    }
    const downloadFile = (data) => {
        window.open(data, '_blank');
    }
    useEffect(() => {
        getData();
    }, [0])

    // const dispatch = useDispatch();
    // const Galleries = useSelector(state => state.Galleries);
    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    // const [album, setAlbum] = useState([]);
    // const [showAlbum, setShowAlbum] = useState(false);



    // const [IMAGES, setIMAGES] = useState([]);

    // useEffect(() => {
    //     let tempImages = [];
    //     album && album.length > 0 && album.map((img, index) => {
    //         tempImages = [...tempImages, { src: getImageUrl(img.url), thumbnail: getImageUrl(img.formats.thumbnail.url), thumbnailWidth: img.formats.thumbnail.width, thumbnailHeight: img.formats.thumbnail.height }];
    //     })
    //     setIMAGES(tempImages);
    // }, [album])

    // console.log(IMAGES)

    // useEffect(() => {
    //     dispatch(fetchGalleries('status=true&_limit=6'))
    // }, [0])


    return (
        <div>
            <img className="img-fluid mt-n5" src="https://admin.atharvacollege.edu.np/api/uploads/final_MBA_978e183392.jpeg?858484.805" />
            <Container>
                {!loading && data && data.length > 0 &&
                    <React.Fragment>
                        <SectionTitle text="Atharva Former Students Council"
                            description=" "
                        // description="No matter what kind of team you’re in or what role you play, Brilliant Teams gives you the core ingredients to make you and your team a truly brilliant one. You’ll find out exactly what it takes to excel as a team player and leader, as well as common team ‘traps’ to avoid." 
                        />

                        <Row>

                            {data.map((t, i) => {
                                return (
                                    <Col lg={12} key={i}>
                                        <StudentQuote key={i} data={t} left={i % 2 !== 0} />
                                    </Col>

                                )
                            })}
                        </Row>
                    </React.Fragment>
                }
                <Row className="my-5 justify-content-center">
                    {loading &&
                        <Loading />
                    }

                    {!loading && data.length === 0 &&
                        <EmptyHolder text="Alumni informations Not Available" />
                    }
                </Row>
            </Container>
        </div>

    )
}

export default Alumni;