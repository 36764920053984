import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeBanners } from '../../api/api';
import { fetchBannersSuccess, fetchBannersFailure } from '../actions/Banners';
import { GET_BANNERS } from '../constants';

const getBannersAsync = async (params) => await takeBanners(params);

function* getBanners({payload}) {
    const {params} = payload;

    try {
        const banners = yield call(getBannersAsync, params);
        if(banners){
            yield put(fetchBannersSuccess(banners.data))
        }
        else{
            yield put(fetchBannersFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchBannersFailure(error));
    }
}

function* watchGetBanners(){
    yield takeEvery(GET_BANNERS, getBanners);
}

export default function* eventsSaga(){
    yield all([
        fork(watchGetBanners)
    ])
}