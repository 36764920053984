import { GET_STUDENT_VOICE, GET_STUDENT_VOICE_SUCCESS, GET_STUDENT_VOICE_FAILURE } from "../constants";

export const fetchStudentVoice =(params) =>({
    type: GET_STUDENT_VOICE,
    payload: {params: params}
})

export const fetchStudentVoiceSuccess = (StudentVoice) =>({
    type: GET_STUDENT_VOICE_SUCCESS,
    payload: {StudentVoice: StudentVoice}
})

export const fetchStudentVoiceFailure = (err) =>({
    type: GET_STUDENT_VOICE_FAILURE,
    payload: {err: err}
})