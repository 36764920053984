import React, { useEffect, useState } from 'react';
import './Career.scss';
import { Container, Row, Col, Modal } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchGalleries } from '../../redux/actions/Galleries';
// import ImageLoader from 'react-imageloader';
// import { getImageUrl } from '../../utils/ImageUrl';
// import Gallery from 'react-grid-gallery';
import { takeAlumnis, takePartners } from '../../api/api';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import Loading from '../../Components/Loading/Loading';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import TeamItem from '../../Components/TeamItem/TeamItem';
import CareerItem from '../../Components/CareerItem/CareerItem';



const Career = props => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [err, setErr] = useState(false);
    const getData = async (data) => {
        setLoading(true);
        takePartners().then((data) => {
            setData(data.data);
            setLoading(false);
            setErr(false);
        }).
            catch(err => {
                console.log(err)
                setLoading(false);
                setErr(err);
                setData(false)
            });
    }
    const downloadFile = (data) => {
        window.open(data, '_blank');
    }
    useEffect(() => {
        getData();
    }, [0])



    return (
        <Container>
            {!loading && data && data.length > 0 &&
                <React.Fragment>
                    <SectionTitle left text="Placement & Business Incubation Partner"
                        description=" "
                    />

                    <Row>

                        {data.filter(d=> d.type === 'PLACEMENT').map((t, i) => {
                            return (
                                <>
                                    <Col lg={2} className="mb-4">
                                        <CareerItem data={t} />
                                    </Col>
                                </>

                            )
                        })}
                    </Row>
                    <SectionTitle left text="Internship"
                        description=" "
                    />
                    <Row>

                        {data.filter(d=> d.type === 'INTERNSHIP').map((t, i) => {
                            return (
                                <>
                                    <Col lg={2} className="mb-4">
                                        <CareerItem data={t} />
                                    </Col>
                                </>

                            )
                        })}
                    </Row>
                </React.Fragment>
            }
            <Row className="my-5 justify-content-center">
                {loading &&
                    <Loading />
                }

                {!loading && data.length === 0 &&
                    <EmptyHolder text="Alumni informations Not Available" />
                }
            </Row>



        </Container>
    )
}

export default Career;