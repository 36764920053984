import React from 'react';
import { getImageUrl } from '../../utils/ImageUrl';
import CustomButton from '../CustomButton/CustomButton';
import SectionTitle from '../SectionTitle/SectionTitle';
import './CareerItem.scss';

const CareerItem = (props) => {
    const {data} = props;
    return (
        <div className="career-item-wrapper d-flex flex-column align-items-center">
            <img src={data.ImageUrl ? getImageUrl(data.ImageUrl.url) : `http://placehold.jp/3d4070/ffffff/300x200.png?text=${data.Title}`} width="100%"/>
            <SectionTitle titleStyle={{marginTop: '15px', marginBottom: '0px',}} className="my-0" text={data?.Title} headingStyle={{fontSize: '16px', fontWeight: 'bold'}}></SectionTitle>
            <p className="text-center">
                {data?.Description}
            </p>
            {data?.url && <CustomButton onClick={()=>{
                window.open(data?.url, '_blank');
            }} text="Apply Now" type="bordered" variant="primary"/>}
        </div>
    )
}

export default CareerItem;