import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CustomButton from '../../Components/CustomButton/CustomButton';
import './Downloads.scss';
import {takeDownloads} from '../../api/api';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import {getImageUrl} from '../../utils/ImageUrl';
// const data = ['asdas', 'asdasd', 'sdasd'];
export const Downloads = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [err, setErr] = useState(false);
    const getData = async (data) => {
        setLoading(true);
        takeDownloads().then((data)=>{
            setData(data.data);
            setLoading(false);
            setErr(false);
        }).
        catch(err =>{
            console.log(err)
            setLoading(false);
            setErr(err);
            setData(false)
        });
    }
    const downloadFile = (data) => {
        window.open(data, '_blank');
    }
    useEffect(() => {
        getData();
    },[0])
    return (
        <Container>
            <Row className="my-5">
                {!loading && data && data.map((dt, i) => {
                    return (
                        <Col lg={3} className="my-4">
                            <SectionTitle headingStyle={{fontSize: '24px'}} text={dt?.Title} left className="text-left">
                            </SectionTitle>
                    <p>{dt?.Description}</p>
                            <CustomButton
                            onClick={()=>{
                                downloadFile(dt?.Media ? getImageUrl(dt?.Media.url) : dt?.MediaUrl)
                            }}
                             text="Download" 
                             variant="primary" type="solid"/>
                        </Col>
                    )
                })}
            </Row>
            <Row className="my-5 justify-content-center">
                {loading &&
                    <Loading />
                }

                {!loading && data.length === 0 &&
                    <EmptyHolder text="Download items are Not Available" />
                }
            </Row>
        </Container>
    )
}


export default Downloads;