import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeGalleries } from '../../api/api';
import { fetchGalleriesSuccess, fetchGalleriesFailure } from '../actions/Galleries';
import { GET_GALLERIES } from '../constants';

const getGalleriesAsync = async (params) => await takeGalleries(params);

function* getGalleries({payload}) {
    const {params} = payload;

    try {
        const galleries = yield call(getGalleriesAsync, params);
        if(galleries){
            yield put(fetchGalleriesSuccess(galleries.data))
        }
        else{
            yield put(fetchGalleriesFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchGalleriesFailure(error));
    }
}

function* watchGetGalleries(){
    yield takeEvery(GET_GALLERIES, getGalleries);
}

export default function* galleriesSaga(){
    yield all([
        fork(watchGetGalleries)
    ])
}