import { UPDATE_ADMISSION_DATA, CREATE_ADMISION_REQUEST, CREATE_ADMISION_SUCCESS, CREATE_ADMISION_FAILURE } from "../constants";

export const updateAdmissionData = (key, value) => ({
    type: UPDATE_ADMISSION_DATA,
    payload: {key: key, value: value}
})

export const admissionRequest = (data) => ({
    type: CREATE_ADMISION_REQUEST,
    payload: {data: data}
})

export const admissionRequestSuccess = (response) => ({
    type: CREATE_ADMISION_SUCCESS,
    payload: {response: response}
})

export const admissionRequestFailure = (error) => ({
    type: CREATE_ADMISION_FAILURE,
    payload: {error: error}
})