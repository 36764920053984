import React, { useEffect, useState } from 'react';
import './TopBar.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { history } from '../../redux/store';

const TopBar = props => {
    const { data } = props;
    const [noticeIndex, setNoticeIndex] = useState(0);
    useEffect(() =>{
        setTimeout(() => {
            if(noticeIndex < data.length - 1) {
                setNoticeIndex(noticeIndex + 1);
            }
            else{
                setNoticeIndex(0);
            }
        }, 5000)
    })
    return (
        <div className="top-bar-wrapper">
            <Container>
                <Row>
                    <Col lg={7}>
                        <div className="d-flex align-items-center py-2">
                            <h6 className="mr-3 mb-0 text-bold">NOTICES:</h6>
                            <div className="notices">
                                {data && data.map((news, index) => {
                                    return (
                                        <CSSTransition
                                        in={index === noticeIndex}
                                        timeout={300}
                                        classNames="alert"
                                        unmountOnExit
                                        // onEnter={() => setShowButton(false)}
                                        // onExited={() => setShowButton(true)}
                                        >
                                            <p onClick={()=>{
                                                history.push(`news/${news.slug}`)
                                            }} className={`mb-0 ${index !== noticeIndex ? 'd-none' : ''}`}>{news.title}</p>
                                        </CSSTransition>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TopBar;