import { GET_FACILITIES, GET_FACILITIES_SUCCESS, GET_FACILITIES_FAILURE } from "../constants"

const INIT_STATE = {
    facilities: false,
    fetching: false,
    error: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FACILITIES:
            return { ...state, fetching: true, error: false }

        case GET_FACILITIES_SUCCESS:
            return { ...state, fetching: false, error: false, facilities: action.payload.facilities }

        case GET_FACILITIES_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        default:
            return { ...state }
    }
}