import React from 'react';
import './StudentLife.scss';
import { Row, Col, Container, Carousel } from 'react-bootstrap';

import './StudentLife.scss';
import CustomButton from '../../../Components/CustomButton/CustomButton';
import { ReactComponent as LightLogo } from '../../../assets/logo-light.svg';
import Section from '../../../Components/Section/Section';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Stories from '../../../Components/Stories/Stories';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import { getImageUrl } from '../../../utils/ImageUrl';

const monologs = Array(3).fill(null);

const StudentLife = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'student-voices py-5 px-5',
        autoplay: true,
        autoplaySpeed: 10000
    };

    const { data, loading } = props;
    return (

        <Section title="Students Speak" className="mb-3" background="primary" 
        // description="Plus one for this feature. A simple text description that would allow some basic html formatting would be fine – no need for a wysiwyg editor. Description should be above the gallery and below the title"
        >
            <Container className="mt-n5">
                <Row className="align-items-center">
                    {/* <Col lg={2} className="d-none d-md-flex">
                        <LightLogo style={{ transform: 'rotate(-90deg)' }} wid />
                    </Col> */}
                    <Col lg={12}>
                        <Row className="mb-5">
                            <Col lg={12}>
                                <Slider {...settings}>
                                    {data && data.map((st, index) => {
                                        return (
                                            <div className="item">
                                                <Row className="align-items-center">
                                                    <Col lg={8}>
                                                        <p className="text-center">“{st.content}”</p>

                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="caption flex-column text-center d-flex align-items-center justify-content-center">
                                                            <div className="image-holder mb-2">
                                                                <img src={st.imageUrl ? getImageUrl(st.imageUrl.url) : 'http://placehold.jp/50x50.png'} alt="Student Voice" />
                                                            </div>
                                                            <div className="students-descriptions ml-3 d-flex justify-content-start flex-column">
                                                                <h5 style={{ color: 'rgb(193, 193, 193)' }} className="text-bold text-gray">{st.studentName}</h5>
                                                                <p style={{ marginBottom: "0px", fontSize: '16px', color: 'rgb(193, 193, 193)' }}>{st.designation}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        )
                                    })}
                                    {loading && <Loading className="mb-5 d-flex justify-content-center" />}
                                    {!loading && data.length === 0 &&
                                        <Col lg={12} className="d-flex justify-content-center">
                                            <EmptyHolder text="Nothing Found" />
                                        </Col>
                                    }
                                </Slider>
                            </Col>
                        </Row>
                        {/* <Row className="my-5 pb-4">
                            {
                                monologs && monologs.map((st) => {
                                    return(
                                        <Col lg={4}>
                                        <Stories />
                                    </Col>
                                    )
                                })
                            }
                        </Row> */}
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default StudentLife;