import React, { useEffect } from 'react';
import './Team.scss';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import TeamItem from '../../Components/TeamItem/TeamItem';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeams } from '../../redux/actions/Teams';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';

const teammembers = Array(10).fill(null);

const Team = props => {
    const dispatch = useDispatch();
    const Teams = useSelector(state => state.Teams);

    useEffect(() => {
        dispatch(fetchTeams('status=true'));

    }, [0])
    return (
        <Container className="mb-5">
            {!Teams.fetching && Teams.teams.length > 0 &&
                <React.Fragment>
                    <SectionTitle text="Management Team" 
                    description=" "
                    // description="No matter what kind of team you’re in or what role you play, Brilliant Teams gives you the core ingredients to make you and your team a truly brilliant one. You’ll find out exactly what it takes to excel as a team player and leader, as well as common team ‘traps’ to avoid." 
                    />

                    <Row>

                        {Teams.teams.map((t, i) => {
                            return (
                                <>
                                {i%5 === 0 && <Col xs={6} lg={1}></Col>}
                                <Col lg={2} className="mb-4">
                                    <TeamItem data={t} />
                                </Col>
                                </>
                                
                            )
                        })}
                    </Row>
                </React.Fragment>
            }
            <Row className="my-5 justify-content-center">
                {Teams.fetching &&
                    <Loading />
                }

                {!Teams.fetching && Teams.teams.length === 0 &&
                    <EmptyHolder text="Team informations Not Available" />
                }
            </Row>
        </Container>
    )
}

export default Team;