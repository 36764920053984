import { GET_STUDENT_VOICE, GET_STUDENT_VOICE_SUCCESS, GET_STUDENT_VOICE_FAILURE } from "../constants"

const INIT_STATE = {
    voices: false,
    fetching: false,
    error: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STUDENT_VOICE:
            return { ...state, fetching: true, error: false }

        case GET_STUDENT_VOICE_SUCCESS:
            return { ...state, fetching: false, error: false, voices: action.payload.StudentVoice }

        case GET_STUDENT_VOICE_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        default:
            return { ...state }
    }
}