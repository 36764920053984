import React from 'react';
import './Section.scss';
import SectionTitle from '../SectionTitle/SectionTitle';

const Section = props => {
    const { title, description, background } = props;
    return (
        <div className={`section py-4 ${props.className ? props.className : ''} background--${background}`} style={props.style}>
            {title &&
                <div className="section-title pb-5">
                    <SectionTitle type="primary" text={title} description={description} />
                </div>
            }
            {props.children}
        </div>
    )
}

export default Section;