import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeTeams } from '../../api/api';
import { fetchTeamsSuccess, fetchTeamsFailure } from '../actions/Teams';
import { GET_TEAMS } from '../constants';

const getTeamsAsync = async (params) => await takeTeams(params);

function* getTeams({payload}) {
    const {params} = payload;

    try {
        const teams = yield call(getTeamsAsync, params);
        if(teams){
            yield put(fetchTeamsSuccess(teams.data))
        }
        else{
            yield put(fetchTeamsFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchTeamsFailure(error));
    }
}

function* watchGetTeams(){
    yield takeEvery(GET_TEAMS, getTeams);
}

export default function* teamsSaga(){
    yield all([
        fork(watchGetTeams)
    ])
}