import React, { useState } from 'react';
import Event from '../../../Components/Event/Event';
import { Container, Col, Row, ListGroup, Modal } from 'react-bootstrap';
import './EventSection.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import Loading from '../../../Components/Loading/Loading';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import { getImageUrl } from '../../../utils/ImageUrl';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)


const EventSection = (props) => {
    const { data, loading } = props;
    const [details, setDetails] = useState(false);
    const [detailData, setDetailData] = useState(false);

    const [slideIndex, setSlideIndex] = useState(0)
    const styles = {
        slide: {
            padding: 0,
            minHeight: 100,
            color: '#fff',
        },
        slide1: {
            backgroundColor: '#FEA900',
        },
        slide2: {
            backgroundColor: '#B3DC4A',
        },
        slide3: {
            backgroundColor: '#6AC0FF',
        },
    };

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <div className="event--section">
            <Container>
                <Row className="m-0 justify-content-start" style={{ minHeight: "300px" }}>
                    {/* <Col lg={2} className="p-0">
                        <div className="vertical-bar">
                            <h1 className="text-uppercase text-bolder">Events</h1>
                        </div>
                    </Col> */}
                    <Col lg={8} className={`px-0 px-md-5`}>

                        <h4 className="text-bolder my-4 pl-4">Next Events</h4>
                        {/* {data &&
                            <AutoPlaySwipeableViews
                                index={slideIndex}
                                onChangeIndex={index => setSlideIndex(index)}
                                className="event-main-slider"
                                enableMouseEvents
                                autoplay={false}
                                direction="incremental"
                                interval={6000}
                                springConfig={{ duration: '0.7s', easeFunction: 'ease-in-out', delay: '0s' }}
                            > */}
                        <Row className="m-0">
                            {data && data.slice(0,2).map((ev, index) => {
                                return (
                                    // <ScrollAnimation animateIn='slide-in-right'>
                                    <Col lg={6}>
                                        <Event data={ev} />
                                    </Col>
                                    // </ScrollAnimation>
                                )
                            })}
                        </Row>
                        {/* </AutoPlaySwipeableViews>
                        } */}
                    </Col>
                    <Col lg={4} className="event-list-items">
                        {data && data.length > 2 && <h4 className="my-4 text-bolder">Recent Events</h4>}
                        <div className="py-3">
                            <ListGroup>
                                {data && data.slice(2,6).map((ev, index) => {
                                    return (
                                        <ListGroup.Item className="mb-3 py-3" onClick={() => {
                                            setDetailData(ev)
                                            setDetails(true)
                                            }}>
                                            <h5>{ev.title || 'Event Title'}</h5>
                                            <p>{(new Date(ev.eventDate)).toLocaleDateString("en-US", options)}</p>
                                        </ListGroup.Item>
                                    )
                                })}

                            </ListGroup>
                        </div>

                    </Col>


                    {loading && <Loading className="mb-5" />}
                    {!loading && data.length === 0 &&
                        <Col lg={8} className="d-flex">
                            <EmptyHolder text="Event Not Found" />
                        </Col>
                    }


                    <Modal
                        show={details}
                        centered
                        size="lg"
                        onHide={() => {
                            setDetails(false)
                            setDetailData(false)
                            // setModal(false);
                            // setFormStep(1)
                            // history.push('/')
                        }}
                    >
                        <Modal.Body>
                            <img style={{ width: '100%' }} src={detailData.imageUrl ? getImageUrl(detailData.imageUrl.url) : 'http://placehold.jp/3d4070/ffffff/300x200.png?text=Atharva%20College'} />

                            <div className={`event-wrapper px-0 px-md-5 ${props.className}`}>
                                <span className={`event-tag`}>{detailData.category || 'Category'}</span>
                                <div className="event--details">
                                    <h4 className="text-uppercase text-bold">{detailData.title || 'Event Title'}</h4>
                                    <p className="my-4 text-bold text-gray text-uppercase">{(new Date(detailData.eventDate)).toLocaleDateString("en-US", options)}</p>
                                    <p className="paragraph">{detailData.description || "description"}</p>
                                </div>
                            </div>
                            <div className="px-5" dangerouslySetInnerHTML={{ __html: data.eventDetails || 'N/A' }}></div>
                        </Modal.Body>
                    </Modal>

                </Row>
            </Container>
        </div>

    )
}

export default EventSection;