import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as LogoLightFooter } from '../../assets/logo-light.svg';
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/twitter.svg';
import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/youtube.svg';
import './Footer.scss';
import { history } from '../../redux/store';
const FooterHead = props => {
    return (
        <div className="footer-header mb-3 d-flex align-items-center">
            <h5 className="text-uppercase text-white">{props.text}</h5>
            <div className="footer-design flex-fill">
                <p className="m-0 ml-3 flex-fill"></p>
            </div>
        </div>
    )
}
const Footer = (props) => {
    const { data } = props;
    const onClickNavMenu = (value) => {
        history.push(value);
    }
    return (
        <div className="footer-wrapper">
            <div className="footer-bottom pt-5">
                <Container>
                    <Row className="align-items-start py-5">
                        <Col lg={4} className="d-flex flex-column align-items-start mb-5 mb-md-0">
                            <FooterHead text="Courses" />
                            <a onClick={() => {
                                onClickNavMenu('/course/bachelor-of-business-administration')
                            }}>Bachelor of Business Administration</a>
                            <a
                                onClick={() => {
                                    onClickNavMenu('/course/bachelor-of-hotel-management')
                                }}
                            >Bachelor of Hotel Management</a>
                            <a
                                onClick={() => {
                                    onClickNavMenu('/course/bachelor-of-health-care-management-1')
                                }}
                            >Bachelor of Health Care Management</a>
                            <a
                                onClick={() => {
                                    onClickNavMenu('/course/master-of-business-administration')
                                }}
                            >Master of Business Administration</a>
                            <a
                                onClick={() => {
                                    onClickNavMenu('/course/mhcm')
                                }}
                            >Master of Health Care Management</a>
                        </Col>
                        <Col lg={4} className="d-flex flex-column align-items-start mb-5 mb-md-0">
                            <FooterHead text="Useful Links" />
                            <a
                                onClick={() => {
                                    onClickNavMenu('/about-us')
                                }}
                            >About Us</a>
                            <a
                                onClick={() => {
                                    onClickNavMenu('/contact-us')
                                }}
                            >Contact Us</a>
                            <a
                                onClick={() => {
                                    onClickNavMenu('/team')
                                }}
                            >Our Team</a>
                        </Col>
                        {/* <Col lg={3} className="d-flex flex-column align-items-start mb-5 mb-md-0">
                            <FooterHead text="Navigation" />
                            <a>Bachelor of Social Work</a>
                            <a>Bachelor Arts</a>
                            <a>Bachelor in Business Studies</a>
                        </Col> */}

                        <Col lg={3} className="social-navigation mt-0 mt-md-n5 p-0">
                            <div className="footer-social pt-0 pt-md-5 mt-0 mt-md-n5 px-0">
                                <div className="px-5 my-5 pb-0 pb-md-4">
                                    <LogoLightFooter />

                                </div>
                                <div className="social-icons d-flex align-items-center justify-content-around py-0 py-md-4 mt-5 px-5">
                                    {data && data.facebookUrl &&
                                        <a href={data.facebookUrl} target="_blank"><Facebook /></a>
                                    }
                                    {data && data.twitterUrl &&
                                        <a href={data.twitterUrl} target="_blank"><Twitter /></a>
                                    }
                                    {data && data.instagramUrl &&
                                        <a href={data.instagramUrl} target="_blank"><Instagram /></a>
                                    }
                                    {data && data.youtubeUrl &&
                                        <a href={data.youtubeUrl} target="_blank"><Youtube /></a>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="footer-copyright">
                    <Container className="d-flex justify-content-center py-5">
                        <p className="copy-right m-0">Copyright ©2020 All rights reserved | IT Department, Atharva Business College </p>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default Footer;