import React from 'react';
import './Loading.scss';
const Loading = (props) => {
    const {text, className} = props;
    return (
        <div className={`loading--wrapper ${className}`} style={props.style}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            <h6 className="mt-3 text-bold">Please Wait!!!</h6>
            {text && <p>{props.text}</p>}
        </div>
    )
}

export default Loading;