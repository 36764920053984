import React from 'react';
import { ReactComponent as CompleteIcon } from '../../assets/complete.svg';
import { ReactComponent as ProgressIcon } from '../../assets/progress.svg';
import { ReactComponent as NextIcon } from '../../assets/next.svg';
import './StepHeaderItem.scss';

const StepHeaderItem = props => {
    const { icon, title, status } = props;
    const mainColor = (status === 'complete' || status === 'progress') ? 'green' : 'gray';
    const textColor = (status === 'complete' || status === 'progress') ? 'black' : 'gray';

    return (
        <div className="step-header-wrapper d-flex justify-content-center align-items-center">
            {!status ? (
                <NextIcon width={30} />
            ) : (
                    <div>
                        {status === 'complete' && <CompleteIcon width={30} fill={mainColor} />}
                        {status === 'progress' && <ProgressIcon width={30} fill={mainColor} />}
                    </div>
                )
            }


            <h5 className="mb-0" style={{ color: textColor, marginLeft: '20px' }}>{title}</h5>
        </div >
    )
}

export default StepHeaderItem;