import { GET_ADVERTISEMENT, GET_ADVERTISEMENT_SUCCESS, GET_ADVERTISEMENT_FAILURE } from "../constants";

export const fetchAdvertisements =(params) =>({
    type: GET_ADVERTISEMENT,
    payload: {params: params}
})

export const fetchAdvertisementsSuccess = (advertisements) =>({
    type: GET_ADVERTISEMENT_SUCCESS,
    payload: {advertisements: advertisements}
})

export const fetchAdvertisementsFailure = (err) =>({
    type: GET_ADVERTISEMENT_FAILURE,
    payload: {err: err}
})