import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeNews } from '../../api/api';
import { fetchNewsSuccess, fetchNewsFailure, fetchNewsDetailsSuccess, fetchNewsDetailsFailure } from '../actions/News';
import { GET_NEWS, GET_NEWS_DETAILS } from '../constants';

const getNewsAsync = async (params) => await takeNews(params);

function* getNews({payload}) {
    const {params} = payload;

    try {
        const teams = yield call(getNewsAsync, params);
        if(teams){
            yield put(fetchNewsSuccess(teams.data))
        }
        else{
            yield put(fetchNewsFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchNewsFailure(error));
    }
}

function* watchGetNews(){
    yield takeEvery(GET_NEWS, getNews);
}



// get news Details

const getNewsDetailsAsync = async (params) => await takeNews(params);

function* getNewsDetails({payload}) {
    const {params} = payload;

    try {
        const teams = yield call(getNewsDetailsAsync, params);
        if(teams){
            yield put(fetchNewsDetailsSuccess(teams.data))
        }
        else{
            yield put(fetchNewsDetailsFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchNewsDetailsFailure(error));
    }
}

function* watchGetNewsDetails(){
    yield takeEvery(GET_NEWS_DETAILS, getNewsDetails);
}




export default function* newsSaga(){
    yield all([
        fork(watchGetNews),
        fork(watchGetNewsDetails)
    ])
}