import { GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE } from "../constants";

export const fetchEvents =(params) =>({
    type: GET_EVENTS,
    payload: {params: params}
})

export const fetchEventsSuccess = (events) =>({
    type: GET_EVENTS_SUCCESS,
    payload: {events: events}
})

export const fetchEventsFailure = (err) =>({
    type: GET_EVENTS_FAILURE,
    payload: {err: err}
})