import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import TeamItem from '../../Components/TeamItem/TeamItem';
import './BOD.scss';
const bodMembers = [{
    id: 2,
    fullName: "Prakash Gharti",
    designation: "Chairman"
}]
const BOD = (props) => {
    return (
        <div className="bod-wrapper">
            <SectionTitle text="Board of Directors"
                description=" "
            // description="No matter what kind of team you’re in or what role you play, Brilliant Teams gives you the core ingredients to make you and your team a truly brilliant one. You’ll find out exactly what it takes to excel as a team player and leader, as well as common team ‘traps’ to avoid." 
            />
            <Container>
                {/* <Row>
                    {bodMembers.map((t, i) => {
                        return (
                            <Col lg={3} className="mb-4">
                                <TeamItem data={t} />
                            </Col>
                        )
                    })}
                </Row> */}
                <Row className="my-5 justify-content-center">
                    <EmptyHolder text="Content not found"/>
                </Row>
            </Container>
        </div>
    )
}

export default BOD;