import { all } from 'redux-saga/effects'
import organizationSaga from './organizationSaga';
import bannerSaga from './bannersSaga';
import eventsSaga from './eventsSaga';
import facilitiesSaga from './facilitiesSaga';
import coursesSaga from './courseSaga';
import teamsSaga from './teamSaga';
import galleriesSaga from './galleriesSaga';
import studentVoiceSaga from './studentVoiceSaga';
import newsSaga from './newsSaga';
import advertisementSaga from './advertisementSaga';
export default function* rootSaga(getState) {
    yield all([
        organizationSaga(),
        bannerSaga(),
        eventsSaga(),
        facilitiesSaga(),
        coursesSaga(),
        teamsSaga(),
        galleriesSaga(),
        studentVoiceSaga(),
        newsSaga(),
        advertisementSaga(),
    ]);
}