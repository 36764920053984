import { GET_ORGANIZATION_DETAILS, GET_ORGANIZATION_DETAILS_SUCCESS, GET_ORGANIZATION_DETAILS_FAILURE } from "../constants"

const INIT_STATE = {
    fetching: false,
    info: false,
    error: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ORGANIZATION_DETAILS:
            return { ...state, fetching: true, error: false }
        case GET_ORGANIZATION_DETAILS_SUCCESS:
            return { ...state, fetching: false, info: action.payload.data }
        case GET_ORGANIZATION_DETAILS_FAILURE:
            return { ...state, fetching: false, error: action.payload.error }
        default:
            return { ...state }
    }
}