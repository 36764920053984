import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeEvents } from '../../api/api';
import { fetchEventsSuccess, fetchEventsFailure } from '../actions/Events';
import { GET_EVENTS } from '../constants';

const getEventsAsync = async (params) => await takeEvents(params);

function* getEvents({payload}) {
    const {params} = payload;

    try {
        const events = yield call(getEventsAsync, params);
        if(events){
            yield put(fetchEventsSuccess(events.data))
        }
        else{
            yield put(fetchEventsFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchEventsFailure(error));
    }
}

function* watchGetEvents(){
    yield takeEvery(GET_EVENTS, getEvents);
}

export default function* bannerSaga(){
    yield all([
        fork(watchGetEvents)
    ])
}