import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './SubNavigation.scss';
import { Row, Col } from 'react-bootstrap';
import { history } from '../../redux/store';

const SubNavigation = props => {
    const { data, top } = props;
    return (
        <div className="sub-navigation-wrapper" style={{ top: top }}>
            <Row className="justify-content-left">
                {data && data.map((item, index) => {
                    return (
                        <Col lg={4}>
                            <h4 className="text-primary text-uppercase text-bold"
                                onClick={() => {
                                    props.onClick();
                                    history.push(item.path)
                                }
                                }>
                                {item.title}
                            </h4>
                            <div className="child-sub-menu">
                                {item.child && item.child.map((child, index) => {
                                    return (
                                        <p onClick={() => {
                                            props.onClick();
                                            history.push(child.path)
                                        }}>{child.title}</p>
                                    )
                                })}
                            </div>
                        </Col>
                    )


                })}

            </Row>

        </div>
    )
}

export default SubNavigation;