import { GET_GALLERIES, GET_GALLERIES_SUCCESS, GET_GALLERIES_FAILURE } from "../constants"

const INIT_STATE = {
    galleries: false,
    fetching: false,
    error: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_GALLERIES:
            return { ...state, fetching: true, error: false }

        case GET_GALLERIES_SUCCESS:
            return { ...state, fetching: false, error: false, galleries: action.payload.galleries }

        case GET_GALLERIES_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        default:
            return { ...state }
    }
}