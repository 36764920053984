import React from 'react';
import './AboutSection.scss';
import Section from '../Section/Section';
import { Container, Row, Col } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading/Loading';
import EmptyHolder from '../EmptyHolder/EmptyHolder';
import { getImageUrl } from '../../utils/ImageUrl';
const AboutSection = (props) => {
    const history = useHistory();
    const { loading, data } = props;
    return (
        <Section background="gray" className="about-section-wrapper py-5">
            <Container>
                {!loading && data &&
                    <Row className="position-relative py-5">
                        <Col lg={9}>
                            <div className="video-container">
                                <img className="thumnail" src={data.coverVideoImage ? getImageUrl(data.coverVideoImage.url) : 'http://placehold.jp/1125x750.png'} alt="About Section" />
                                <div className="overlay-content d-flex justify-content-center align-items-center">
                                    {data.coverVideo &&
                                        <div>
                                            <a class="play-btn" onClick={props.onClick}></a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="background--primary floating--content py-5 px-5">
                            <p className="text-bold text-uppercase" style={{ color: '#cdcdcd' }}>About Atharva</p>
                            <h2 className="text-bolder mb-5">A Few Words About the College</h2>
                                <p className="paragraph mb-5">{data.organizationIntro ? data.organizationIntro : ''}</p>
                            <CustomButton onClick={() => history.push('/about-us')} size="big" type="bordered" variant="default" text="Learn More" />
                        </Col>
                    </Row>
                }
                <Row className="m-0 justify-content-center">
                    {loading &&
                        <Loading />
                    }

                    {!loading && data.length === 0 &&
                        <EmptyHolder text="Organization Info Not Available" />
                    }
                </Row>
            </Container>
        </Section>
    )
}

export default AboutSection;