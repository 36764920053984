import React from 'react';
import { getImageUrl } from '../../utils/ImageUrl';
import './StudentQuote.scss';

const StudentQuote = (props) => {
    const { data, left } = props;
    return (
        <div className={`voice-wrapper d-flex align-items-center  mb-5 ${left ? 'flex-row-reverse' : ''}`}>
            <div className={`person-image ${left ? 'ml-5' : 'mr-5'}`}>
                <img src={data?.imageUrl ? getImageUrl(data?.imageUrl.url) : 'http://placehold.jp/150x150.png'} />
            </div>
            <div className="voice-content px-5 py-4">
                <p className="mb-1 text-center text-bold"><span style={{fontSize: '48px'}}>"</span></p>
                <p>{data?.content}</p>
                <p className="text-bold">{data?.studentName}/{data?.designation}</p>
            </div>
        </div>
    )
}

export default StudentQuote;