import Axios from "axios";
export const baseUrl = "https://admin.atharvacollege.edu.np/api";

export const takeOrganizationDetails = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/about-college?status=true`
    })
}

export const takeBanners = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/banners?${data ? data : ''}`
    })
}

export const takeDownloads = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/downloads?${data ? data : ''}`
    })
}

export const takePartners = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/partners?${data ? data : ''}`
    })
}

export const takeAlumnis = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/alumni?${data ? data : ''}`
    })
}

export const takeEvents = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/events?${data ? data : ''}`
    })
}

export const takeFacilities = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/features?${data ? data : ''}`
    })
}
export const takeCourses = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/courses?${data ? data : ''}`
    })
}

export const takeCourseDetails = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/courses?${data ? data : ''}`
    })
}


export const takeTeams = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/our-teams?${data ? data : ''}`
    })
}
export const takeAdvertisements = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/advertisements?${data ? data : ''}`
    })
}

export const takeNews = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/blogs?${data ? data : ''}`
    })
}

export const takeGalleries = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/galleries?${data ? data : ''}`
    })
}

export const takeStudentVoice = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/student-vices?${data ? data : ''}`
    })
}

export const requestAdmission = (data) => {
    return Axios({
        method: 'POST',
        url: `${baseUrl}/admission-requests`,
        data: data
    })
}

export const requestInformation = (data) => {
    return Axios({
        method: 'POST',
        url: `${baseUrl}/request-informations`,
        data: data
    })
}

