import React, { useContext, useState } from 'react';
import { ReactComponent as LogoDark } from '../../assets/logo-dark.svg';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/closeAlt.svg';
import { ThemeContext } from '../../utils/ThemeContext';
import { Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { history } from '../../redux/store';
const MobileNavigation = props => {
    const { menu } = props;
    const theme = useContext(ThemeContext)
    const [activeBar, setActiveBar] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const onClickNavButton = () => {
        history.push('/career')
    }
    const navigateMenu = (data) => {
        if(data.child){
            setActiveItem(data.label)
        }
        else{
            history.push(data.path)
            setActiveBar(false)
        }
        
    }
    return (
        <div className={`mobile-navigation ${activeBar ? 'active' : ''}`}>
            <div className="top-bar-stripe">
                <LogoDark width='30vw' 
                onClick={()=>{
                    history.push('/');
                    setActiveBar(false)
                }}
                />
                {activeBar ? (
                    <CloseIcon width="25px" fill={theme.primaryColor} onClick={() => {
                        setActiveBar(false);
                    }} />

                ) : (
                        <MenuIcon className="menu-shortcut" width="25px" fill={theme.primaryColor} onClick={() => {
                            setActiveBar(true);
                        }} />
                    )}
            </div>
            <div className="bottom-details">
                <Nav className="d-flex flex-column px-5 pt-4">
                    {menu && menu.map((m) => {
                        if (m.type === 'button') {
                            return (
                                <Nav.Item key={m.path}>
                                    <Nav.Link
                                        className="button-style"
                                        onClick={() => {
                                            onClickNavButton();
                                        }}
                                    >
                                        {m.name}
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }
                        else {
                            return (
                                <Nav.Item key={m.path}>
                                    <Nav.Link
                                        onClick={() => {
                                            navigateMenu(m);
                                        }}>
                                        {m.name}
                                        {m.child && m.child.map((child, index) => {
                                            return (
                                                <Nav className={`d-flex flex-column sub-navigation-mobile ${activeItem === m.label ? 'active' : ''}`}>
                                                    <Nav.Item key={child.path}>
                                                        <Nav.Link
                                                            onClick={() => {
                                                                // history.push(child.path)

                                                            }}>
                                                            {child.child && child.child.map((item, index) => {
                                                                return (
                                                                    <Nav className={`d-flex flex-column sub-navigation-mobile ${activeItem === m.label ? 'active' : ''}`}>
                                                                        <Nav.Item key={child.path}>
                                                                            <Nav.Link
                                                                                onClick={() => {
                                                                                    history.push(child.path)
                                                                                    setActiveBar(false);
                                                                                }}>
                                                                                {item.title}
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                )
                                                            })}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            )
                                        })

                                        }
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        }
                    })}
                </Nav>
            </div>
        </div>
    )
}

export default MobileNavigation;