import React from 'react';
import TeamItem from '../../../Components/TeamItem/TeamItem';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import ShowMore from '../../../Components/ShowMore/ShowMore';
import { useHistory } from 'react-router-dom';
import Section from '../../../Components/Section/Section';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
const teammembers = Array(4).fill(null);

const OurTeam = (props) => {
    let history = useHistory();
    const { data, loading } = props;
    return (
        <Section title="Management Team" 
        description=" "
        // description="Our mission is to inspire and develop people to achieve their goals in STEM – one person, one question, and one small commitment to learning at a time."
        >
            <Container>
                <Row className="justify-content-center">

                    <Col lg={12}>
                        <Row>
                            {data && data.map((member, index) => {
                                return (
                                    <>
                                    {index%5 === 0 && <Col xs={12} lg={1}></Col>}
                                    <Col xs={6} lg={2} className="mb-4">
                                        <TeamItem data={member}/>
                                    </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </Col>
                    {loading && <Loading className="mb-5" />}
                    {!loading && data.length === 0 &&
                        <Col lg={12} className="d-flex justify-content-center">
                            <EmptyHolder text="Team Not Found" />
                        </Col>
                    }
                </Row>
                {data && data.length > 4 &&
                    <ShowMore
                        onClick={() => {
                            history.push('/team')
                        }}
                    />
                }

            </Container>
        </Section>
    )
}

export default OurTeam;