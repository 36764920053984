import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeFacilities } from '../../api/api';
import { fetchFacilitiesSuccess, fetchFacilitiesFailure } from '../actions/Facilities';
import { GET_FACILITIES } from '../constants';

const getFacilitiesAsync = async (params) => await takeFacilities(params);

function* getFacilities({payload}) {
    const {params} = payload;

    try {
        const facilities = yield call(getFacilitiesAsync, params);
        if(facilities){
            yield put(fetchFacilitiesSuccess(facilities.data))
        }
        else{
            yield put(fetchFacilitiesFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchFacilitiesFailure(error));
    }
}

function* watchGetFacilities(){
    yield takeEvery(GET_FACILITIES, getFacilities);
}

export default function* facilitiesSaga(){
    yield all([
        fork(watchGetFacilities)
    ])
}