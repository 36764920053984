import React, { useState, useRef, useEffect } from 'react';
import './TeamItem.scss';
import Card from '../../Components/Card/Card';
import TeamMember from '../../assets/team-member.jpg';
import ImageLoader from 'react-imageloader';
import ScrollAnimation from 'react-animate-on-scroll';
import { ReactComponent as FacebookAlt } from '../../assets/facebook-alt.svg';
import { ReactComponent as TwitterAlt } from '../../assets/twitter-alt.svg';
import { ReactComponent as LinkedinAlt } from '../../assets/linkedin-alt.svg';
import { getImageUrl } from '../../utils/ImageUrl';
const TeamItem = props => {
    const { data } = props;
    return (
        <ScrollAnimation animateIn="swing-in-top-fwd">
            <div className="team-item-wrapper d-flex flex-column align-items-center justify-content-center">
                <div className="main-holder">
                    <ImageLoader className="team-image" src={data.imageUrl ? getImageUrl(data.imageUrl.url) : 'http://placehold.jp/258x388.png'} alt="Team Member" />
                    <div className="absolute-content">
                        <div className="social-icons-profile d-flex flex-column align-items-start justify-content-center ml-4 mb-4 mt-auto">
                            {data.facebookUrl && <a href={data.facebookUrl || "#"}><FacebookAlt className="my-2" /></a>}
                            {data.twitterUrl && <a href={data.twitterUrl || "#"}><TwitterAlt className="my-2" /></a>}
                            {data.LinkedinUrl && <a href={data.LinkedinUrl || "#"}><LinkedinAlt className="my-2" /></a>}


                        </div>
                    </div>
                </div>
                <div className="member-details py-4 text-center" >
                    <h5 className="text-bold">{data.fullName || 'Full Name'}</h5>
                    <p>{data.designation || 'Designation'}</p>
                </div>
            </div>
        </ScrollAnimation>
    )
}

export default TeamItem;