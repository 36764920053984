import React from 'react';
import './NewsStories.scss';
import Section from '../Section/Section';
import { Container, Col, Row } from 'react-bootstrap';
import NewsItem from '../../Components/NewsItem/NewsItem';
import Loading from '../Loading/Loading';
import EmptyHolder from '../EmptyHolder/EmptyHolder';

const NewsArticle = Array(4).fill(null);
const NewsStories = (props) => {
    const { data, loading } = props;
    return (
        <Section title="News & Stories">
            <Container>
                <Row>
                    {data && data.length > 0 &&
                        <React.Fragment>
                            <Col lg={5}>
                                <NewsItem data={data[0]} size="big" />
                            </Col>
                            <Col lg={7} className="pl-3 pl-lg-5">
                                {data.slice(1, data.length).map((news, index) => {
                                    return <NewsItem key={index} data={news} />
                                })}
                            </Col>
                        </React.Fragment>
                    }
                    {loading && <Loading />}
                    {!loading && data.length === 0 &&
                        <Col lg={12} className="d-flex justify-content-center">
                            <EmptyHolder text="News Not Found" />
                        </Col>
                    }
                </Row>
            </Container>
        </Section>
    )
}

export default NewsStories;