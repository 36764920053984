import React, { useContext } from 'react';
import {ReactComponent as ArrowDown} from '../../assets/arrow-down.svg';
import { ThemeContext } from '../../utils/ThemeContext';
import './ShowMore.scss';

const ShowMore = (props) =>{
    const { variant} = props;

    const theme = useContext(ThemeContext);
    return (
        <div className="show-more-wrapper d-flex flex-column align-items-center" onClick={props.onClick}>
            <span style={{color: (variant ? (variant === 'primary' ? theme.primaryColor : theme.secondaryColor) : 'black'), fontWeight: '700', textTransform: 'uppercase'}}>Show More</span>
            <ArrowDown fill={variant ? (variant === 'primary' ? theme.primaryColor : theme.secondaryColor) : 'black'} width="20px"/>
        </div>
    )
}

export default ShowMore;