import { UPDATE_ADMISSION_DATA, CREATE_ADMISION_REQUEST, CREATE_ADMISION_SUCCESS, CREATE_ADMISION_FAILURE } from "../constants"

const INIT_STATE = {
    loading: true,
    course: '',
    fullName: '',
    dob: '',
    gender: '',
    address: '',
    address1: '',
    address2: '',
    phone: '',
    email: '',
    applicationLevel: 2,
    academicHistory: {},
    creating: false,
    created: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_ADMISSION_DATA:
            return { ...state, [action.payload.key]: action.payload.value }

        case CREATE_ADMISION_REQUEST:
            return { ...state, creating: true, created: false }

        case CREATE_ADMISION_SUCCESS:
            return { ...state, creating: false, created: true }

        case CREATE_ADMISION_FAILURE:
            return { ...state, creating: false, created: false, error: action.payload.error }

        default:
            return { ...state }
    }
}