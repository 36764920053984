import React, { useEffect } from 'react';
import './Event.scss';
import EventSection from '../Dashboard/EventSection/EventSection';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';
import Event from '../../Components/Event/Event';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../redux/actions/Events';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
const Events = props => {
    const events = useSelector(state => state.Events);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchEvents('status=true&_sort=eventDate:desc'))
    }, [0])
    return (
        <div className="mt-n5">
            <Container className="mt-5 pt-5">
            <SectionTitle left text="Annual Events" />
                <Row>


                    {!events.fetching && events.events && events.events.filter((e)=> e.category == 'Annual').map((data, index) => {
                        return (
                            <Col lg={4} >
                                <Event data={data} />
                            </Col>
                        )
                    })
                    }

                </Row>
                <SectionTitle left text="Other Events" />
                <Row>


                    {!events.fetching && events.events && events.events.filter((e)=> e.category != 'Annual').map((data, index) => {
                        return (
                            <Col lg={4} >
                                <Event data={data} />
                            </Col>
                        )
                    })
                    }

                </Row>
                <Row className="my-5 justify-content-center">
                    {events.fetching &&
                        <Loading />
                    }

                    {!events.fetching && !events.events &&
                        <EmptyHolder text="Team informations Not Available" />
                    }
                </Row>
            </Container >
        </div >
    )
}

export default Events;