import React from 'react';
import './SectionTitle.scss';
// import ScrollAnimation from 'react-animate-on-scroll';
const SectionTitle = (props) => {
    const { variant, type } = props;
    const className = variant === 'light' ? 'title-light' : 'title-default';
    return (
        // <ScrollAnimation animateIn='tracking-in-contract-bck'>
        <React.Fragment>
            <div className={`section-title-wrapper d-flex ${props.left ? 'justify-content-start' : 'justify-content-center'} ${props.className} ${className}`} style={props.style}>
                <div className="title-content" style={props.titleStyle}>
                    <h2 style={props.headingStyle}>{props.text}</h2>
                </div>
            </div>
            {props.description &&
                <p className="text-center d-flex justify-content-center mb-5">
                    <span className="title-description" style={{ width: "40%", display: 'block', color: type === 'primary' ? '#C1C1C1' : '#626A77', fontSize: '18px' }}>
                        {props.description}
                    </span>
                </p>
            }

        </React.Fragment>
        // </ScrollAnimation>
    )
}

export default SectionTitle;