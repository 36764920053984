import { GET_TEAMS, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE } from "../constants";

export const fetchTeams =(params) =>({
    type: GET_TEAMS,
    payload: {params: params}
})

export const fetchTeamsSuccess = (teams) =>({
    type: GET_TEAMS_SUCCESS,
    payload: {teams: teams}
})

export const fetchTeamsFailure = (err) =>({
    type: GET_TEAMS_FAILURE,
    payload: {err: err}
})