import React from 'react';
import {ReactComponent as ArrowRight} from '../../assets/arrow-right.svg';
import './CourseCard.scss';
import { useHistory } from 'react-router-dom';
import { getImageUrl } from '../../utils/ImageUrl';
const CourseCard = (props) => {
    const history = useHistory();
    const {data} = props;
    return (
        <React.Fragment>


            <svg class="svg">
                <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox"><path d="M0.03,0.004 L0.98,0.064 C0.993,0.065,1,0.072,1,0.081 V0.925 C1,0.934,0.993,0.941,0.98,0.942 L0.03,1 C0.015,1,0.003,0.997,0.003,0.987 V0.02 C0.003,0.01,0.015,0.003,0.03,0.004"></path></clipPath>
            </svg>
            <svg class="svg">
                <clipPath id="my-clip-path-left" clipPathUnits="objectBoundingBox"><path d="M0.976,0.002 L0.025,0.062 C0.012,0.063,0.003,0.07,0.003,0.079 V0.923 C0.003,0.932,0.012,0.939,0.025,0.94 L0.976,1 C0.99,1,1,0.995,1,0.985 V0.018 C1,0.009,0.99,0.001,0.976,0.002"></path></clipPath>
            </svg>

            <div class="clipped"></div>

            <div className={`course-card-wrapper ${props.className}`} onClick={()=> history.push(`/course/${data.slug}`)}>
                <div className="course-container">
                    <div className="course-image">
                        <img height="551" src={getImageUrl(data.coverImage.url) || 'https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX2372878.jpg'} />
                    </div>
                    <div className="course-details d-flex align-items-end">
                        <div className="d-flex align-items-start flex-column pb-5">
                            <h2 className="text-uppercase text-bold">{data.shortName || "Short Name"}</h2>
    <p>{data.title || "Course Title"}</p>
                            <div className="button button-custom">
                                <span className="text-uppercase text-bold">Learn More</span>
                                <ArrowRight fill="white" width="30"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CourseCard;