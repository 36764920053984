import React from 'react';
import './EmptyHolder.scss';
import { ReactComponent as EmptyIcon } from '../../assets/error.svg';

const EmptyHolder = (props) => {
    return (
        <div className="empty-holder d-flex justify-content-center align-items-center">
            <div className="text-center">
                <EmptyIcon width="50px" />
                <h6 className="mt-3" style={{color: '#e0e0e0'}}>{props.text && props.text}</h6>
            </div>
        </div>
    )
}

export default EmptyHolder;