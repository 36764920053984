import React from 'react';
import { getImageUrl } from '../../utils/ImageUrl';

export const Advertisement = (props) => {
    const {data} = props;
    return(
        <div>
            {data && 
            <>
            {data && data.images && data.images.length > 0 && data.images.map((d,i)=>{
                return(
                    <img width="100%" src={d.url ? getImageUrl(d.url) : ''}/>
                )
            })}
            <div className="mt-3" dangerouslySetInnerHTML={{__html:data?.description}}>

            </div>
            </>
            }
        </div>
    )
}

export default Advertisement;