import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import Loading from '../../Components/Loading/Loading';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import StudentQuote from '../../Components/StudentQuote/StudentQuote';
import { fetchStudentVoice } from '../../redux/actions/StudentVoice';

const StudentVoice = (props) => {
    const dispatch = useDispatch();
    const Student = useSelector(state => state.Student);

    useEffect(() => {
        dispatch(fetchStudentVoice('status=true&_limit=20'))

    }, [0])
    return (
        <Container>
            <SectionTitle className="mb-4" text="Students Speak"></SectionTitle>
            <Row>
                {Student.voices && Student.voices.map((v,i)=>{
                    return(
                        <Col lg={12}>
                        <StudentQuote data={v} left={i%2 !== 0}/>
                    </Col>
                    )
                })}
                {Student.fetching && <Loading className="mb-5 d-flex justify-content-center" />}
                {!Student.fetching && Student.voices.length === 0 &&
                    <Col lg={12} className="d-flex justify-content-center">
                        <EmptyHolder text="Nothing Found" />
                    </Col>
                }
            </Row>
        </Container>
    )
}

export default StudentVoice;