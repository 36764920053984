import { GET_TEAMS, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE } from "../constants"

const INIT_STATE = {
    teams: false,
    fetching: false,
    error: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEAMS:
            return { ...state, fetching: true, error: false }

        case GET_TEAMS_SUCCESS:
            return { ...state, fetching: false, error: false, teams: action.payload.teams }

        case GET_TEAMS_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        default:
            return { ...state }
    }
}