import { GET_NEWS, GET_NEWS_SUCCESS, GET_NEWS_FAILURE, GET_NEWS_DETAILS, GET_NEWS_DETAILS_SUCCESS, GET_NEWS_DETAILS_FAILURE } from "../constants";

export const fetchNews =(params) =>({
    type: GET_NEWS,
    payload: {params: params}
})

export const fetchNewsSuccess = (news) =>({
    type: GET_NEWS_SUCCESS,
    payload: {news: news}
})

export const fetchNewsFailure = (err) =>({
    type: GET_NEWS_FAILURE,
    payload: {err: err}
})


//get news Details

export const fetchNewsDetails =(params) =>({
    type: GET_NEWS_DETAILS,
    payload: {params: params}
})

export const fetchNewsDetailsSuccess = (news) =>({
    type: GET_NEWS_DETAILS_SUCCESS,
    payload: {news: news}
})

export const fetchNewsDetailsFailure = (err) =>({
    type: GET_NEWS_DETAILS_FAILURE,
    payload: {err: err}
})