import React, { useRef, useEffect, useState } from 'react';
import { Nav, Container } from 'react-bootstrap';
import './NavigationBar.scss';
import { history } from '../../redux/store';
import { ReactComponent as LogoLight } from '../../assets/logo-light.svg';
import { ReactComponent as LogoDark } from '../../assets/logo-dark.svg';
import { useSelector } from 'react-redux';
import SubNavigation from '../SubNavigation/SubNavigation';

const WebNavigation = (props) => {
    const { menu } = props;
    const navRef = useRef();
    const [solidNav, setSolidNav] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const route = useSelector(state => state.router)

    const onscrollAdjust = () => {
        let scrollPosition = window.scrollY;
        if (scrollPosition > 50) {
            setSolidNav(true);
        }
        else {
            setSolidNav(false);

        }
    };
    const onChildMenu = (props) => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        if (props) {
            setOverlay(props);
            // window.onscroll = () => {
            //     window.scrollTo(scrollLeft, scrollTop); 
            // }
        }
        else setOverlay(false);
    }

    const onClickNavButton = () => {
        history.push('/career')
    }
    useEffect(() => {

        if (route.location.pathname === '/') {
            setSolidNav(false);
            window.addEventListener("scroll", onscrollAdjust);
            props.onChange(0)
        }
        else {
            setSolidNav(true)
            props.onChange(navRef.current.clientHeight)
            return window.removeEventListener("scroll", onscrollAdjust);
        }
    }, [route]);
    return (
        <Nav ref={navRef} className={`navbar-dark bg-transparent custom-navigation py-3 position-fixed align-items-center ${solidNav ? "solid-nav pt-3" : "pt-5"} `}>

            <Container className="d-flex justify-content-around navogation-inner">
                <a className="mr-5" onClick={() => {
                    history.push('/')
                }}>
                    {solidNav ? (
                        <LogoDark />
                    ) : (
                            <LogoLight />
                        )}
                </a>
                <div className="navigation-right ml-5 ml-lg-auto align-items-center d-flex">
                    <Nav>
                        {menu && menu.map((m) => {
                            if (m.type === 'button') {
                                return (
                                    <Nav.Item key={m.path}>
                                        <Nav.Link
                                            className="button-style"
                                            onClick={() => {
                                                onClickNavButton();
                                                setOverlay(false);
                                            }}>
                                            {m.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            }
                            else {
                                return (
                                    <Nav.Item key={m.path} className="parent-navigation">
                                        <Nav.Link className="parent-menu">

                                            <span
                                                onClick={() => {
                                                    history.push(m.path)
                                                    setOverlay(false);
                                                }}
                                            >
                                                {m.name}
                                            </span>
                                            {m.child &&
                                                <div className="child-menu-wrapper">
                                                    <ul key={m.child} className="child-menu">
                                                        {m.child.map((menu, index) => {
                                                            return (
                                                                <li className="child-section">
                                                                    {/* <span>{menu.title}</span> */}
                                                                    {menu.child &&
                                                                        <ul className="child-items">
                                                                            {menu.child.map((cm, index) => {
                                                                                return (
                                                                                    <li key={index} className="child-item">
                                                                                        <a onClick={() => {
                                                                                            history.push(cm.path)
                                                                                            // setOverlay(false);
                                                                                        }}>
                                                                                            {cm.title}
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            }
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            }
                        })}
                    </Nav>
                </div>
            </Container>
        </Nav>
    )
}

export default WebNavigation;