import React, { useState } from 'react';
import NavigationBar from '../Components/NavigationBar/NavigationBar';
import { useSelector } from 'react-redux';
import Footer from '../views/Footer/Footer';
import NavigationBarAlt from '../Components/NavigationBar/NavigationBarAlt';
import { isMobile } from 'react-device-detect';
import MobileNavigation from '../Components/NavigationBar/MobileNavigation';
import WebNavigation from '../Components/NavigationBar/WebNavigation';
import WebNavigationAlt from '../Components/NavigationBar/WebNavigationAlt';

const Layout = (props) => {
    const { menuItems } = props;
    const [spaceTop, setSpaceTop] = useState(0);
    const route = useSelector(state => state.router)
    const Organization = useSelector(state => state.Organization)
    const pageChange = (e) => {
        setSpaceTop(e)
    }
    return (
        <React.Fragment>
            {isMobile ? (
                <MobileNavigation menu={menuItems}/>
            ): (
                    <React.Fragment>
            {route.location.pathname === '/' ? (
                <WebNavigation menu={menuItems} onChange={(e) => pageChange(e)} />
            ) : (
                    <WebNavigationAlt menu={menuItems} onChange={(e) => pageChange(e)} />
                )}
        </React.Fragment>
    )
}
<div className="main" style={{ paddingTop: spaceTop }}>
    {props.children}
</div>
    <Footer data={Organization.info}/>

        </React.Fragment >
    )
}

export default Layout;