import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeCourses, takeCourseDetails } from '../../api/api';
import { fetchCoursesSuccess, fetchCoursesFailure, fetchCourseDetailsSuccess, fetchCourseDetailsFailure } from '../actions/Courses';
import { GET_COURSES, GET_COURSE_DETAILS } from '../constants';

const getCoursesAsync = async (params) => await takeCourses(params);

function* getCourses({payload}) {
    const {params} = payload;

    try {
        const courses = yield call(getCoursesAsync, params);
        if(courses){
            yield put(fetchCoursesSuccess(courses.data))
        }
        else{
            yield put(fetchCoursesFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchCoursesFailure(error));
    }
}

function* watchGetCourses(){
    yield takeEvery(GET_COURSES, getCourses);
}


//get course details
const getCourseDetailsAsync = async (params) => await takeCourseDetails(params);

function* getCourseDetails({payload}) {
    const {params} = payload;

    try {
        const courses = yield call(getCourseDetailsAsync, params);
        if(courses){
            yield put(fetchCourseDetailsSuccess(courses.data))
        }
        else{
            yield put(fetchCourseDetailsFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchCourseDetailsFailure(error));
    }
}

function* watchGetCourseDetails(){
    yield takeEvery(GET_COURSE_DETAILS, getCourseDetails);
}

export default function* coursesSaga(){
    yield all([
        fork(watchGetCourses),
        fork(watchGetCourseDetails)
    ])
}