import React, { useEffect, useState } from 'react';
import './Gallery.scss';
import Gallery from 'react-grid-gallery';
import ScrollContainer from 'react-indiana-drag-scroll'
import { getImageUrl } from '../../../utils/ImageUrl';

import { Row, Col } from 'react-bootstrap';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import Loading from '../../../Components/Loading/Loading';
const ImgGallery = (props) => {
    const {data, loading} = props;
    const [IMAGES, setIMAGES] = useState([]);

    useEffect(() => {
        let tempImages = [];
        props.data && props.data.length > 0 && props.data.map((img, index) => {
            tempImages = [...tempImages, { src: getImageUrl(img.url), thumbnail: getImageUrl(img.formats.thumbnail.url), thumbnailWidth: img.formats.thumbnail.width, thumbnailHeight: img.formats.thumbnail.height }];
        })
        setIMAGES(tempImages);
    }, [props])

    return (
        <Row className="m-0 mb-3">
            <Col>
                <ScrollContainer
                hideScrollbars={false}
                >
                    <Gallery images={IMAGES} />
                </ScrollContainer>

            </Col>
            {loading && <Loading />}
            {!loading && data.length === 0 &&
                <Col lg={12} className="d-flex justify-content-center">
                    <EmptyHolder text="Images Not Found" />
                </Col>
            }
        </Row>


    )
}

export default ImgGallery;