import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import chairpersonImage from '../../assets/chairperson.jpg';
import Loading from '../../Components/Loading/Loading';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import './Messages.scss';
const halfStyle = {
    height: '100%',
    backgroundColor: '#006F3D',
    padding: '10px'
}

const Messages = (props) => {
    const info = useSelector(state => state.Organization);
    const loading = info?.fetching;
    return (

        <Container>
            {!loading &&

                <Row>
                    <Col lg={4}>
                        <div style={halfStyle}>
                            <img src="https://admin.atharvacollege.edu.np/api/uploads/Dr_Hira_Lal_Shrestha_Principal_249b1f20fa.jpeg" className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg={8} className="d-flex justify-content-center align-items-center flex-column">
                        {/* <img src={principalMessage} width="50%"/> */}
                        <SectionTitle text="Message from the Principal"></SectionTitle>
                        <div className="mb-4" style={{ lineHeight: '32px' }} dangerouslySetInnerHTML={{ __html: info?.info?.principalMessage }}></div>
                    </Col>
                </Row>
            }
            <Row className="my-5 justify-content-center">
                {loading &&
                    <Loading />
                }
            </Row>
        </Container>
    )
}

export default Messages;