import React, { useEffect, useState } from 'react';
import './Contact.scss';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Card from '../../Components/Card/Card';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import { useForm } from "react-hook-form";
import { ReactComponent as LogoDark } from '../../assets/logo-dark.svg';
import ImageLoader from 'react-imageloader';
import CustomButton from '../../Components/CustomButton/CustomButton';
import Loading from '../../Components/Loading/Loading';
import { ReactComponent as CompleteIcon } from '../../assets/complete.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';
import { requestInformation } from '../../api/api';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationDetails } from '../../redux/actions/organizationInfo';
import {ReactComponent as QRCODE} from '../../assets/abc-qr.svg';


const Contact = props => {
    const { register, handleSubmit, setError, errors } = useForm({ mode: 'onBlur' });

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const info = useSelector(state => state.Organization && state.Organization.info)
    

    const onSubmit = (value) => {
        onApplicationSubmit(value)
    }
    const onApplicationSubmit = async value => {
        let admissionData = {
            fullName: value.fullName,
            email: value.email,
            message: value.message,
            action: 'New'

        }
        setModal(true);
        setLoading(true);
        await requestInformation(admissionData).then((data) => {
            setLoading(false);
            setError(false);
        })
            .catch(err => {
                setError(err.response.data);
                setLoading(false);
            })
        setTimeout(() => {
        }, 3000)
    }

    useEffect(() => {
        dispatch(fetchOrganizationDetails())
    }, [0])

    return (
        <div className="contact-page pb-5">
            <Modal
                show={modal}
                centered
                onHide={() => {
                    setModal(false);
                    history.push('/')
                }}
            >
                {!loading &&
                    <Modal.Header closeButton>

                    </Modal.Header>
                }
                <Modal.Body>
                    {loading ? (
                        <Loading className="py-5" text="We are recording your application" />
                    ) : (
                            <div className="py-5">
                                {!formError ? (
                                    <div className="success-response">
                                        <CompleteIcon />

                                        <h5 className="mt-3 text-bold">Congratulations!!</h5>
                                        <p className="text-center px-4">We have received your application. One of our representatives will get back to your very soon</p>
                                        <h6 className="mt-4 text-bold">Thank you.</h6>
                                    </div>
                                ) : (
                                        <div className="success-response">
                                            <ErrorIcon fill="red" width="50px" />
                                            <h3 className="mt-3 text-bold" style={{ color: 'red' }}>Error!!</h3>
                                            <p className="text-center px-4">Something Went Wrong</p>
                                            <h6 className="mt-4 text-bold">Please Try Again Later.</h6>
                                        </div>
                                    )}

                            </div>
                        )}

                </Modal.Body>
            </Modal>
            <Container>
                <SectionTitle text="GET IN TOUCH"
                // description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," 
                />
                <Row className="justify-content-center align-items-center">
                    <Col lg={5}>
                        <Card className="py-5 px-5">
                            <Row>
                                {/* <Col lg={3} className="d-flex justify-content-center align-items-center">
                                    <LogoDark style={{transform: 'rotate(-90deg'}}/>
                                </Col> */}
                                <Col lg={12}>
                                    <div className="label-group">
                                        <h4>Address</h4>
                            <p>{info && info?.address}</p>
                                    </div>
                                    {/* <div className="label-group">
                                        <h4>Working Hours</h4>
                                        <p>Sunday - Friday: 06 AM - 05 PM <br/>
                                        Saturday: 10:00- 02:00</p>
                                    </div> */}
                                    <div className="label-group">
                                        <h4>Phone</h4>
                                        <p>{info && info?.primaryPhone} / {info && info?.secondaryPhone}</p>
                                    </div>
                                    <Row>
                                        <Col lg={7}>
                                        <div className="label-group">
                                        <h4>WhatsApp</h4>
                                    <a href="https://wa.me://+9779851132648" target="_blank" title="Share on whatsapp">9851132648</a>
                                    {/* <a href="telegram://send?text=WHATEVER_LINK_OR_TEXT_YOU_WANT_TO_SEND" title="Share on whatsapp">Telegram</a> */}

                                    </div>
                                    <div className="label-group">
                                        <h4>Viber</h4>
                                    <a href="Viber://chat?number=+9779851132648" title="Share on whatsapp">9851132648</a>

                                    </div>
                                    <div className="label-group">
                                        <h4>Telegram</h4>
                                    <a href="https://t.me/atharvabusinesscollege" target="_blank" title="Share on whatsapp">Atharva Business College</a>

                                    </div>
                                        </Col>
                                        <Col lg={5}>
                                        <QRCODE/>
                                        </Col>
                                    </Row>
                                    <div className="label-group">
                                        <h4>Email</h4>
                                        <p>{info && info?.primaryEmail}</p>
                                    </div>

                                    <div className="label-group">
                                        <CustomButton onClick={()=> window.open('https://goo.gl/maps/sgFYmZfmq1tL1vZLA', '_blank')} type="solid " variant="primary" text="Find us in google map" />
                                    </div>
                                </Col>

                            </Row>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card style={{ boxShadow: 'none' }} className="py-5 px-5">
                            <Row className="justify-content-start">

                                <Col lg={12}>
                                    <div className="d-block py-3 text-bold"><h5 className="text-bold">Send us message</h5></div>

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input
                                                required
                                                placeholder=""
                                                className="form-control"
                                                name="fullName"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            {errors.fullName && <p className="form-error">{errors.fullName.message ? errors.fullName.message : "Your Name is Required"}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input className="form-control" name="email" type="email" ref={register({ required: true })} />
                                            {errors.email && <p className="form-error">{errors.email.message ? errors.email.message : "Email is Required"}</p>}

                                        </div>
                                        <div className="form-group">
                                            <label>Message</label>
                                            <textarea className="form-control" name="message" rows="5" type="text" ref={register({ required: true })} />
                                            {errors.message && <p className="form-error">{errors.message.message ? errors.message.message : "Message can not be empty!"}</p>}

                                        </div>

                                        <button
                                            type="submit"
                                            className="custom-button mt-3 button-primary button-solid"
                                        >Submit</button>
                                    </form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Contact;