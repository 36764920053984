import React, { useEffect, useState, useRef } from 'react';
import { Nav, Container } from 'react-bootstrap';
import './WebNavigation.scss';
import { history } from '../../redux/store';
import {ReactComponent as LogoDark} from '../../assets/logo-dark.svg';
import SubNavigation from '../SubNavigation/SubNavigation';

const WebNavigationAlt = (props) => {
    const { menu } = props;
    const [overlay, setOverlay] = useState(false);
    const navRef = useRef();
    const onClickNavButton = () => {
        history.push('/career')

    }

    const onChildMenu = (props) => {
        if(props) setOverlay(props);
        else setOverlay(false);
    }
    useEffect(() =>{
        props.onChange(120)
    }, [0])

    return (
        <Nav ref={navRef} className={`navbar-dark bg-transparent custom-navigation py-3 position-fixed align-items-center solid-nav pt-3`}>
            <div style={{paddingTop: navRef.current && navRef.current.clientHeight}} className={`overlay ${overlay ? 'visible' : 'hidden'}`} onMouseLeave={()=>{setOverlay(false)}}>
                <Container style={{color: 'white', paddingTop: "30px", borderTop: "1px solid #e0e0e0"}}>
                    <SubNavigation onClick={()=>setOverlay(false)} data={overlay}/>
                </Container>
            </div>
            <Container className="d-flex justify-content-around">
                <div className="mr-5" onClick={() => {
                    history.push('/')
                }}>
                    <LogoDark/>
                </div>
                <div className="navigation-right ml-0 ml-lg-auto align-items-center d-flex">
                    <Nav>
                    {menu && menu.map((m) => {
                            if (m.type === 'button') {
                                return(
                                    <Nav.Item key={m.path}>
                                    <Nav.Link
                                    className="button-style"
                                        onClick={() => {
                                            onClickNavButton();
                                            setOverlay(false);

                                        }}>
                                        {m.name}
                                    </Nav.Link>
                                </Nav.Item>
                                )
                            }
                            else {
                                return (
                                    <Nav.Item key={m.path} className="parent-navigation">
                                        <Nav.Link className="parent-menu" onClick={() => {
                                                if(!m.child){
                                                    history.push(m.path)
                                                }
                                            // setOverlay(false);
                                            }}>

                                            {m.name}
                                            {m.child &&
                                                <div className="child-menu-wrapper">
                                                    <ul key={m.child} className="child-menu">
                                                        {m.child.map((menu, index) => {
                                                            return (
                                                                <li className="child-section">
                                                                    {/* <span>{menu.title}</span> */}
                                                                    {menu.child &&
                                                                        <ul className="child-items">
                                                                            {menu.child.map((cm, index) => {
                                                                                return (
                                                                                    <li key={index} className="child-item">
                                                                                        <a onClick={() =>{
                                                                                            history.push(cm.path)
                                                                                        }}>
                                                                                            {cm.title}
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            }
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            }
                        })}
                    </Nav>
                </div>
            </Container>
        </Nav>
    )
}

export default WebNavigationAlt;