import { GET_GALLERIES, GET_GALLERIES_SUCCESS, GET_GALLERIES_FAILURE } from "../constants";

export const fetchGalleries =(params) =>({
    type: GET_GALLERIES,
    payload: {params: params}
})

export const fetchGalleriesSuccess = (galleries) =>({
    type: GET_GALLERIES_SUCCESS,
    payload: {galleries: galleries}
})

export const fetchGalleriesFailure = (err) =>({
    type: GET_GALLERIES_FAILURE,
    payload: {err: err}
})