import { GET_NEWS, GET_NEWS_SUCCESS, GET_NEWS_FAILURE, GET_NEWS_DETAILS, GET_NEWS_DETAILS_SUCCESS, GET_NEWS_DETAILS_FAILURE } from "../constants"

const INIT_STATE = {
    news: false,
    fetching: false,
    error: false,
    selectedNews: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEWS:
            return { ...state, fetching: true, error: false }

        case GET_NEWS_SUCCESS:
            return { ...state, fetching: false, error: false, news: action.payload.news }

        case GET_NEWS_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        //get news Details
        case GET_NEWS_DETAILS:
            return { ...state, fetching: true, error: false, selectedNews: false }

        case GET_NEWS_DETAILS_SUCCESS:
            return { ...state, fetching: false, error: false, selectedNews: action.payload.news[0] }

        case GET_NEWS_DETAILS_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        default:
            return { ...state }
    }
}