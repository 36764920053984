import React from 'react';
import './SlideNavigation.scss';
import {ReactComponent as LeftArrow} from '../../assets/arrow-left.svg';
import {ReactComponent as RightArrow} from '../../assets/arrow-right.svg';

const SlideNavigation = (props) => {
    const {disableLeft, disableRight} = props;
    return (
        <div className="slide-navigation-wrapper d-flex justify-content-between">
            <div className={`left navigation ${disableLeft ? 'disabled' : ''}`} 
            onClick={() =>{!disableLeft && props.onPrevious()}}>
                <LeftArrow fill={disableLeft ? 'gray' : "white"}/>
                <span>Prev</span>
            </div>
            <div className={`right navigation ${disableRight ? 'disabled' : ''}`} 
            onClick={() =>{
                !disableRight && props.onNext()
                }}>
                <RightArrow fill={disableRight ? 'gray' : "white"}/>
                <span>Next</span>

            </div>
        </div>
    )
}

export default SlideNavigation;