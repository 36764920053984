import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import "./Slider.scss";
import { Container, Row, Col } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';
import Pagination from '../Pagination/Pagination';
import SlideNavigation from '../SlideNavigation/SlideNavigation';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading/Loading';
import EmptyHolder from '../EmptyHolder/EmptyHolder';
import { getImageUrl } from '../../utils/ImageUrl';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Slider = (props) => {
    const { loading, data } = props;
    const history = useHistory();
    const [slideIndex, setSlideIndex] = useState(0)
    const styles = {
        slide: {
            padding: 0,
            minHeight: 100,
            color: '#fff',
        },
    };
    return (
        <React.Fragment>
            <AutoPlaySwipeableViews
                index={slideIndex}
                onChangeIndex={index => setSlideIndex(index)}
                className="home-main-slider"
                enableMouseEvents
                autoplay={true}
                style={{ minHeight: '50vh', backgroundColor: '#cccccc' }}
                direction="incremental"
                interval={10000}
                springConfig={{ duration: '0.7s', easeFunction: 'ease-in-out', delay: '0s' }}
            >
                    {data && data.map((item, index) => {
                        return (
                            <div className="banner-item" style={Object.assign({}, styles.slide, styles.slide1)}>
                                {/* <div className="content">
                                </div> */}
                                <div className="overlay d-flex align-items-end pb-lg-5">
                                    {/* <div className="styled-rectangle"></div> */}
                                    <Container className="pb-5">
                                        <div className="content pb-lg-5">
                                            <Row className="pb-lg-5">
                                                <Col lg={5}>
                                                    <h1 className="slider-text">
                                                        {item.title || "Banner Title for Atharva College"}
                                                    </h1>


                                                </Col>
                                                <Col xl={12} className="">
                                                    <CustomButton onClick={() => history.push('/admission')} className="mr-auto mr-md-3 mt-3 mt-md-0" text="Apply Now" variant="default" type="bordered" />
                                                    <CustomButton onClick={() => history.push('/courses')} className="mt-3 mt-md-0" text="View Courses" variant="default" type="bordered" />

                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </div>
                                <img
                                    //  className="kenburns-top" 
                                    width="100%" src={getImageUrl(item.imageUrl.url) || 'http://placehold.jp/1920x895.png'} alt="Banner" />
                            </div>
                        )
                    })}
                    
            </AutoPlaySwipeableViews >
            {loading && <Loading style={{ minHeight: '100vh' }} />}
                    {!loading && data.length === 0 &&
                        <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
                            <EmptyHolder text="Banner Not Found" />
                        </Col>
                    }
            <div className="banner-indicator">

                <Container>
                    {/* <Pagination dots={2} current={slideIndex} onChangeIndex={e => { setSlideIndex(e) }} /> */}
                    {data.length > 1 && <SlideNavigation
                        disableLeft={slideIndex === 0}
                        disableRight={data && slideIndex === (data.length - 1)}
                        onPrevious={e => setSlideIndex(slideIndex - 1)}
                        onNext={e => setSlideIndex(slideIndex + 1)}
                    />}
                </Container>
            </div>

        </React.Fragment>
    )
}

export default Slider;