import { GET_BANNERS, GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE } from "../constants"

export const fetchBanners = (data) => ({
    type: GET_BANNERS,
    payload: {params: data}
})

export const fetchBannersSuccess = (banners) => ({
    type: GET_BANNERS_SUCCESS,
    payload: {banners: banners}
})

export const fetchBannersFailure = (err) => ({
    type: GET_BANNERS_FAILURE,
    payload: {err: err}
})