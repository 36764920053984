import React from 'react';

const Space = props => {
    const {size, type} = props;
    return (
        <div style={{width: type === 'horizontal' ? size : '100%', height: type === 'horizontal' ? 'auto' : size}}>

        </div>
    )
}

export default Space;