import React from 'react';
import './NewsItem.scss';
import Card from '../Card/Card';
import NewsImage from '../../assets/news.jpg';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';
import { useHistory } from 'react-router-dom';
import { getImageUrl } from '../../utils/ImageUrl';

const NewsItem = props => {
    const history = useHistory();
    const { data } = props;
    const { size } = props;
    const redirect = (value) => {
        history.push(`/news/${value}`)
    }
    return (
        <div className="news__wrapper">
            {size === 'big' ? (
                <Card className="pt-0 px-0 news-big mb-4 mt-4">
                    <div className="image">
                        <img style={{ width: '100%' }} src={data.featuredImage ? getImageUrl(data.featuredImage.url) : 'http://placehold.jp/259x293.png'} alt="News Details" />
                    </div>
                    <div className="news-content px-5 py-4">
                        <h5 className="text-bold mb-4">{data.title}</h5>
                        <p className="text-paragraph light mb-4">{data.shortDescription}</p>
                        <CustomButton text="Read More" size="big" variant="primary" type="bordered" onClick={() => redirect(data.slug)}/>
                    </div>
                </Card>
            ) : (
                    <div className="news-small py-4">
                        <Row className="align-items-center" onClick={() => redirect(data.slug)}>
                            <Col lg={4}>
                                <div className="image-holder">
                                    <img style={{ width: '100%' }} src={data.featuredImage ? getImageUrl(data.featuredImage.url) : 'http://placehold.jp/259x293.png'} alt="News Details" />
                                </div>
                            </Col>
                            <Col lg={8} className="">
                                <div className="news-content pt-4 pt-lg-0">
                                    <h5 className="text-bold mb-4">{data.title}</h5>
                                    <p className="text-paragraph light mb-4">{data.shortDescription}</p>
                                    <CustomButton text="Read More" type="solid" variant="primary" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
        </div>
    )
}

export default NewsItem;