import React from 'react';
import "./CustomButton.scss";
const CustomButton = (props) => {
    const {variant, type, size} = props;
    const className = (variant ? `button-${variant}`: "") +" "+ (type ? `button-${type}`: "") + " " + (size ? `button-${size}`: "")
    return (
        <div onClick={props.onClick} className={`custom-button ${props.className} ${className}`} style={props.style ? props.style : {}}>
            {props.text}
        </div>
    )
}

export default CustomButton;