import React, { useState, useEffect } from 'react';
import './Details.scss';
import Section from '../../../Components/Section/Section';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import CustomButton from '../../../Components/CustomButton/CustomButton';
import CourseCard from '../../../Components/CourseCard/CourseCard';
import { fetchCourseDetails, fetchCourses } from '../../../redux/actions/Courses';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import { getImageUrl } from '../../../utils/ImageUrl';

const Details = props => {
    // const [courseData, setCourseData] = useState(course.slice(0, 2));
    const Course = useSelector(state => state.Course);
    const courseData = Course.selectedCourse;
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCourseDetails(`status=true&slug_eq=${props.match.params.slug}`));
        dispatch(fetchCourses(`status=true&_limit=2&slug_ne=${props.match.params.slug}`))

    }, [props.match.params.slug])
    return (
        <div className="course-deattails-wrapper mt-n4">
            {!Course.fetching &&
                <div>
                    {Course.selectedCourse &&
                        <React.Fragment>
                            <img className="cover-image" src={courseData.featuredImage ? getImageUrl(courseData.featuredImage.url) : `http://placehold.jp/1400x300.png`} alt="Hotel Management" />
                            <Container>
                                <Section title={courseData.title} class="text-left">
                                    <p className="paragraph" dangerouslySetInnerHTML={{ __html: courseData.description ? courseData.description : '' }}>

                                    </p>
                                    <Row className="justify-content-between">
                                        <Col lg={6}>
                                            <Accordion defaultActiveKey="0" onSelect={e => {
                                                console.log(e)
                                            }}>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="0">
                                                            <span className="text-bolder light">Program Objectives</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.programOverview ? courseData.programOverview : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="1">
                                                            <span className="text-bolder">Admission Criteria</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.admissionRequirements ? courseData.admissionRequirements : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="2">
                                                            <span className="text-bolder">Tuition Fee & Scholarship</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.feeAndScholarship ? courseData.feeAndScholarship : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="3">
                                                            <span className="text-bolder">Curriculum</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.curriculum ? courseData.curriculum : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="4">
                                                            <span className="text-bolder">Semester Plan</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="4">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.semesterPlan ? courseData.semesterPlan : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="5">
                                                            <span className="text-bolder">Concentration Courses</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="5">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.concentrationCourses ? courseData.concentrationCourses : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="6">
                                                            <span className="text-bolder">Elective Courses</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="6">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.electiveCourses ? courseData.electiveCourses : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="7">
                                                            <span className="text-bolder">Assessment & Grading</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="7">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.assessmentAndGrading ? courseData.assessmentAndGrading : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="11">
                                                            <span className="text-bolder">Class Timing</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="11">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.classTime ? courseData.classTime : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="8">
                                                            <span className="text-bolder">Faculty Member</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="8">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.facultyMember ? courseData.facultyMember : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="9">
                                                            <span className="text-bolder">Visiting Faculties</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="9">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.visitingMembers ? courseData.visitingMembers : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card className="my-4">
                                                    <Card.Header className="p-0">
                                                        <Accordion.Toggle onClick={(e) => console.log(e)} as={Card.Header} variant="link" eventKey="10">
                                                            <span className="text-bolder">Internship & Placement</span>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="10">
                                                        <Card.Body dangerouslySetInnerHTML={{ __html: courseData.internashipAndPlacement ? courseData.internashipAndPlacement : 'N/A' }}>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                            </Accordion>
                                        </Col>
                                        <Col lg={5}>
                                            <img style={{ width: '100%' }} src={courseData.sideImage ? getImageUrl(courseData.sideImage.url) : "http://placehold.jp/700x1300.png"} alt="vertical" />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center my-5">
                                        <Col lg={4} className="d-flex justify-content-center">
                                            <CustomButton variant="primary" type="bordered" text="Apply Now" />
                                        </Col>
                                    </Row>

                                </Section>

                            </Container>

                        </React.Fragment>
                    }
                    {Course.courses &&

                        <Section background="gray" title="Other Courses">
                            <Container className="pb-5">

                                <Row className="pb-5 justify-content-center">
                                    {Course.courses && Course.courses.map((cs, index) => {
                                        return (
                                            <Col lg={3} md={3} style={{ padding: "0px 20px" }}>

                                                <CourseCard data={cs} className={index % 2 === 0 ? "left" : ""} />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Container>

                        </Section>

                    }
                </div>
            }
            <Row className="my-5 justify-content-center">
                {Course.fetching &&
                    <Loading />
                }

                {!Course.fetching && Course.courses.length === 0 &&
                    <EmptyHolder text="Courses are Not Available" />
                }
            </Row>
        </div>
    )
}

export default Details;