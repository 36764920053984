import React, { useContext, useState } from 'react';
import eventCover from '../../assets/event.png';
import { ThemeContext } from "../../utils/ThemeContext";
import CustomButton from "../CustomButton/CustomButton";
import './Event.scss'
import { Row, Col, Modal } from 'react-bootstrap';
import { getImageUrl } from '../../utils/ImageUrl';
import { ReactComponent as CloseIcon} from "../../assets/close.svg";
const Event = (props) => {
    const [details, setDetails] = useState(false);
    const { data } = props;
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return (
        <Row className='align-items-center justify-content-center m-0 my-3'>
            <Col lg={12} className='image-wrapper'>
                <img style={{ width: '100%' }} src={data.imageUrl ? getImageUrl(data.imageUrl.url) : 'http://placehold.jp/3d4070/ffffff/300x200.png?text=Atharva%20College'} />
            </Col>
            <Col lg={12}>
                <div className={`event-wrapper  ${props.className}`} onClick={() => setDetails(true)}>
                    <span className={`event-tag`}>{data.category || 'Category'}</span>
                    <div className="event--details">
                        <h4 className="text-uppercase ">{data.title || 'Event Title'}</h4>
                        <p className="my-2 text-bold text-gray text-uppercase">{(new Date(data.eventDate)).toLocaleDateString("en-US", options)}</p>
                        {/* <p className="paragraph">{data.description || "description"}</p> */}
                    </div>
                </div>
            </Col>

            <Modal
                show={details}
                centered
                size="lg"
                onHide={() => {
                    setDetails(false)
                    // setModal(false);
                    // setFormStep(1)
                    // history.push('/')
                }}
            >
                <Modal.Body>
                    <div onClick={()=> setDetails(false)} style={{ width: '100%' }} className="d-md-none d-flex justify-content-end mb-4">
                        <CloseIcon width={24}/>
                    </div>
                <img style={{ width: '100%' }} src={data.imageUrl ? getImageUrl(data.imageUrl.url) : 'http://placehold.jp/3d4070/ffffff/300x200.png?text=Atharva%20College'} />

                    <div className={`event-wrapper px-0 px-md-5 ${props.className}`}>
                        <span className={`event-tag`}>{data.category || 'Category'}</span>
                        <div className="event--details">
                            <h4 className="text-uppercase text-bold">{data.title || 'Event Title'}</h4>
                            <p className="my-4 text-bold text-gray text-uppercase">{(new Date(data.eventDate)).toLocaleDateString("en-US", options)}</p>
                            <p className="paragraph">{data.description || "description"}</p>
                        </div>
                    </div>
                    <div className="px-5" dangerouslySetInnerHTML={{__html: data.eventDetails || 'N/A'}}></div>
                </Modal.Body>
            </Modal>
        </Row>
    )
}

export default Event;