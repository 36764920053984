import React, { useEffect } from 'react';
import './Course.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import CourseCard from '../../Components/CourseCard/CourseCard';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourses } from '../../redux/actions/Courses';

const Course = props => {
    const Course = useSelector(state => state.Course);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCourses('status=true'))
    }, [0])

    return (
        <Container>
            {!Course.fetching && Course.courses.length > 0 &&
                <React.Fragment>
                    <SectionTitle text="Our Courses" 
                    // description="Undergraduate courses are studies that can lead to a bachelor's degree, diploma or certificate. Our open enrolment means that anyone 16 or older can take undergraduate courses."
                    />

                    <Row className="m-0 mb-5 justify-content-center">
                        {Course.courses.map((cs, index) => {
                            return (
                                <Col lg={3} md={3}>
                                    <ScrollAnimation animateIn='scale-in-center'>

                                        <CourseCard data={cs} className={index%2 !== 0 ? "left" : ""} />
                                    </ScrollAnimation>
                                </Col>
                            )
                        })}
                    </Row>
                </React.Fragment>
            }

            <Row className="my-5 justify-content-center">
                {Course.fetching &&
                    <Loading />
                }

                {!Course.fetching && Course.courses.length === 0 &&
                    <EmptyHolder text="Courses are Not Available" />
                }
            </Row>

        </Container>
    )
}

export default Course;