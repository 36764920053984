import React, { useState } from 'react';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import { Container, Row, Col } from 'react-bootstrap';
import CourseCard from '../../../Components/CourseCard/CourseCard';
import ShowMore from '../../../Components/ShowMore/ShowMore';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from '../../../Components/Section/Section';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
const course = Array(4).fill('a');
const CourseSection = (props) => {
    const { data, loading } = props;
    return (

        <Section title="All Courses">
            <Container>
                <Row className="m-0 justify-content-center">
                    {data && data.map((cs, index) => {
                        return (
                            <Col lg={2} md={2} style={{ padding: "0px 20px", minWidth: "20%" }}>
                                <ScrollAnimation animateIn='scale-in-center'>

                                    <CourseCard data={cs} className={index % 2 !== 0 ? "left" : ""} />
                                </ScrollAnimation>
                            </Col>
                        )
                    })}
                    {loading &&
                        <Loading />
                    }

                    {!loading && data.length === 0 &&
                        <EmptyHolder text="Course Not Found" />
                    }
                </Row>
            </Container>
        </Section>
    )
}

export default CourseSection;