import { GET_FACILITIES, GET_FACILITIES_SUCCESS, GET_FACILITIES_FAILURE } from "../constants";

export const fetchFacilities =(params) =>({
    type: GET_FACILITIES,
    payload: {params: params}
})

export const fetchFacilitiesSuccess = (facilities) =>({
    type: GET_FACILITIES_SUCCESS,
    payload: {facilities: facilities}
})

export const fetchFacilitiesFailure = (err) =>({
    type: GET_FACILITIES_FAILURE,
    payload: {err: err}
})