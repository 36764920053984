import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeStudentVoice } from '../../api/api';
import { fetchStudentVoiceSuccess, fetchStudentVoiceFailure } from '../actions/StudentVoice';
import { GET_STUDENT_VOICE } from '../constants';

const getStudentVoiceAsync = async (params) => await takeStudentVoice(params);

function* getStudentVoice({payload}) {
    const {params} = payload;

    try {
        const events = yield call(getStudentVoiceAsync, params);
        if(events){
            yield put(fetchStudentVoiceSuccess(events.data))
        }
        else{
            yield put(fetchStudentVoiceFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchStudentVoiceFailure(error));
    }
}

function* watchGetStudentVoice(){
    yield takeEvery(GET_STUDENT_VOICE, getStudentVoice);
}

export default function* studentVoiceSaga(){
    yield all([
        fork(watchGetStudentVoice)
    ])
}