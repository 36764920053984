import createSagaMiddleware from 'redux-saga'
import {createStore, applyMiddleware, compose} from 'redux'
import reducers from './reducers/rootReducer';
import sagas from './sagas/rootSaga';
import {createBrowserHistory} from 'history';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
export const history = createBrowserHistory();

let store;

export const configureStore = (initialState) => {
    const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose;

    store = createStore(reducers(history), initialState, compose(applyMiddleware(...middlewares), reduxDevTools));

    sagaMiddleware.run(sagas);

    // if(module.hot){
    //     module.hot.accept("./reducers/rootReducer", () => {
    //         const nextRootReducer = require("./reducers/rootReducer");
    //         store.replaceReducer(nextRootReducer);
    //     });
    // }
    return store
}

export default store;