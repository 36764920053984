import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge, Form, Modal } from 'react-bootstrap';
import './Admission.scss';
import StepHeaderItem from '../../Components/StepHeaderItem/StepHeaderItem';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { useForm } from "react-hook-form";
import { ReactComponent as FemaleIcon } from "../../assets/female.svg";
import { ReactComponent as MaleIcon } from "../../assets/male.svg";
import { ReactComponent as OtherIcon } from "../../assets/close.svg";
import { address } from '../../data/data';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdmissionData } from '../../redux/actions/Admission';
import Loading from '../../Components/Loading/Loading';
import { ReactComponent as CompleteIcon } from '../../assets/complete.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';
import { useHistory } from 'react-router-dom';
import { requestAdmission } from '../../api/api';
import { fetchCourses } from '../../redux/actions/Courses';


const StepForm = (props) => {
    const { currentStep } = props;
    return (
        <div className="step-form-wrapper">
            <div className="step-form-header" style={{ transform: 'translateY(50%)' }}>
                <Row className="justify-content-center mx-0">
                    <Col lg={4} className="p-0">
                        <StepHeaderItem title="Personal Details" status={currentStep > 2 ? 'complete' : (currentStep === 2 ? 'progress' : '')} />
                    </Col>
                    <Col lg={4} className="p-0">
                        <StepHeaderItem title="Contact Details" status={currentStep > 3 ? 'complete' : (currentStep === 3 ? 'progress' : '')} />
                    </Col>
                    <Col lg={4} className="p-0">
                        <StepHeaderItem title="Academic Details" status={currentStep > 4 ? 'complete' : (currentStep === 4 ? 'progress' : '')} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const StepOne = (props) => {
    const dispatch = useDispatch();
    const Course = useSelector(state => state.Course);
    // useEffect(() => {
    //     if(Course.courses){
    //         dispatch(updateAdmissionData('course', Course.courses[0].title));
    //     }

    // }, [Course])
    useEffect(() => {
        dispatch(updateAdmissionData('applicationLevel', 2));
        dispatch(fetchCourses('status=true'))

        dispatch(updateAdmissionData('course', 'Bachelor of Business Administration'));
    }, [0])
    return (
        <React.Fragment>
            <Form className="d-flex justify-content-center flex-column align-items-center">
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="text-center d-block py-3"><h6>Please fill all the informations carefully </h6></Form.Label>
                    <Form.Label className="text-center d-block py-3"><h4>Which course do you want to apply for?</h4></Form.Label>
                    <Form.Control as="select" onChange={(e) => {
                        dispatch(updateAdmissionData('course', e.currentTarget.value));
                        if (e.currentTarget.value.includes('Bachelor')) {
                            dispatch(updateAdmissionData('applicationLevel', 2))
                        }
                        else if (e.currentTarget.value.includes('(10+2)')) {
                            dispatch(updateAdmissionData('applicationLevel', 1))


                        }
                        else {
                            dispatch(updateAdmissionData('applicationLevel', 3))


                        }
                        props.onClick(2);

                    }}>
                        {!Course.fetching && Course.courses && Course.courses.filter(e=> !e.title.toLowerCase().includes('master')).map((c, i) => {
                            return <option>{c.title}</option>

                        })}
                        
                    </Form.Control>
                </Form.Group>
                <CustomButton
                    onClick={e => {
                        props.onClick(2)
                        // testCreate();
                    }}
                    className="mt-5"
                    text="Next"
                    style={{ color: 'white' }}
                    variant="primary" type="solid" />

            </Form>
        </React.Fragment>
    )
}
const StepTwo = (props) => {
    const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
    const [gender, setGender] = useState('');
    const dispatch = useDispatch();
    const onSubmit = (value) => {
        console.log(value);
        props.onClick(3)
    }

    const onChangeGender = (value) => {
        console.log(value)
        setGender(value.currentTarget.value)
        dispatch(updateAdmissionData('gender', value.currentTarget.value));
    }
    return (
        <React.Fragment>
            <Row className="justify-content-center">

                <Col lg={6}>
                    <div className="d-block py-3 text-bold"><h5 className="text-bold">Personal Details </h5></div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>Full Name</label>
                            <input
                                required
                                placeholder=""
                                className="form-control"
                                name="fullName"
                                type="text"
                                placeholder="Your good name"
                                onChange={(e) => {
                                    dispatch(updateAdmissionData('fullName', e.currentTarget.value))
                                    // setError("fullName", {
                                    //     type: "manual",
                                    //     message: "Dont Forget Your Username Should Be Cool!"
                                    // });
                                }}
                                ref={register({ required: true })}
                            />
                            {errors.fullName && <p className="form-error">{errors.fullName.message ? errors.fullName.message : "Your Name is Required"}</p>}
                        </div>
                        {/* <div className="form-group">
                            <label>Date Of Birth</label>
                            <input onChange={(e) => {
                                dispatch(updateAdmissionData('dob', e.currentTarget.value))
                            }} className="form-control" name="dob" type="date" ref={register({ required: true })} />
                            {errors.dob && <p className="form-error">{errors.dob.message ? errors.dob.message : "Date of Birth is Required"}</p>}

                        </div> */}
                        <div className="form-group">
                            <label>Gender</label>
                            <div className="d-flex gender-select">
                                <label className={gender === 'male' ? 'active' : ''} htmlFor="genderMale">
                                    <MaleIcon width="40px" fill={gender === 'male' ? 'white' : 'black'} />
                                    <span>
                                        Male
                                    </span>
                                </label>
                                <input
                                    className="form-control d-none"
                                    name="gender"
                                    type="radio"
                                    value="male"
                                    id="genderMale"
                                    selected={gender === 'male'}
                                    onChange={onChangeGender}
                                    ref={register({ required: true })} />
                                <label className={gender === 'female' ? 'active' : ''} htmlFor="genderFemale">
                                    <FemaleIcon width="40px" fill={gender === 'female' ? 'white' : 'black'} />
                                    <span>Female</span>
                                </label>

                                <input
                                    className="form-control d-none"
                                    name="gender"
                                    type="radio"
                                    value="female"
                                    id="genderFemale"
                                    selected={gender === 'female'}
                                    onChange={onChangeGender}

                                    ref={register({ required: true })} />

                                <label className={gender === 'other' ? 'active' : ''} htmlFor="genderOther">
                                    <OtherIcon width="40px" fill={gender === 'other' ? 'white' : 'black'} />
                                    <span>
                                        Other
                                    </span>
                                </label>

                                <input
                                    className="form-control d-none"
                                    name="gender"
                                    type="radio"
                                    value="other"
                                    id="genderOther"
                                    selected={gender === 'other'}
                                    onChange={onChangeGender}

                                    ref={register({ required: true })} />
                            </div>
                            {errors.gender && <p className="form-error">{errors.gender.message ? errors.gender.message : "Gender is Required"}</p>}

                        </div>
                        <button
                            type="submit"
                            className="custom-button mt-3 button-primary button-solid"
                        >Next</button>
                    </form>
                </Col>
            </Row>
        </React.Fragment>
    )
}
const StepThree = (props) => {
    const { register, handleSubmit, setError, errors } = useForm({ mode: 'onBlur' });
    const [addList, setAddList] = useState(false);
    const [districtList, setDistrictList] = useState(false);
    const [munList, setMunList] = useState(false);
    const [sAddress, setSAddress] = useState({ state: '', district: '', vdcmun: '' });
    const dispatch = useDispatch();
    const onSubmit = (value) => {
        console.log(value);
        props.onClick(4)
    }

    // const onChnageAddress = () => {
    //     dispatch(updateAdmissionData('address', addList[0].name + ',' + districtList[0].name + ',' + munList[0].name));
    // }

    useEffect(() => {
        dispatch(updateAdmissionData('address', { state: addList ? addList[0].name : '', district: districtList ? districtList[0].name : '', vdcmun: munList ? munList[0].name : '' }));

    }, [sAddress])

    useEffect(() => {
        let provinces = address[0].provinces;
        let districts = provinces[0].districts;
        let munVdc = districts[0].vdcmuns;
        setAddList(provinces);
        setDistrictList(districts);
        setMunList(munVdc);
        dispatch(updateAdmissionData('address', { state: provinces ? provinces[0].name : '', district: districts ? districts[0].name : '', vdcmun: munVdc ? munVdc[0].name : '' }));
    }, [0])
    return (
        <React.Fragment>
            <Row className="justify-content-center">

                <Col lg={6}>
                    <div className="d-block py-3 text-bold"><h5 className="text-bold">Contact Details</h5></div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <Row className="m-0">
                                <div className="form-group mr-3">
                                    <label>State</label>
                                    <select
                                        name="state"
                                        className="form-control"
                                        onChange={e => {
                                            setSAddress({ ...sAddress, state: addList[e.currentTarget.value].name })
                                            setDistrictList(addList[e.currentTarget.value].districts)
                                        }}

                                    >
                                        {addList && addList.map((data, index) => {
                                            return <option value={index}>{data.name}</option>
                                        })}

                                    </select>
                                </div>
                                <div className="form-group mr-3">
                                    <label>District</label>
                                    <select
                                        name="state"
                                        className="form-control"
                                        onChange={e => {
                                            setSAddress({ ...sAddress, district: districtList[e.currentTarget.value].name })
                                            setMunList(districtList[e.currentTarget.value].vdcmuns)
                                        }}
                                    >
                                        {districtList && districtList.map((data, index) => {
                                            return <option value={index}>{data.name}</option>
                                        })}

                                    </select>
                                </div>
                                <div className="form-group mr-3">
                                    <label>Municipality/VDC</label>
                                    <select
                                        name="state"
                                        className="form-control"
                                        onChange={e => {
                                            setSAddress({ ...sAddress, vdcmun: e.currentTarget.value })
                                            // setMunList(districtList[e.currentTarget.value].vdcmuns)
                                        }}
                                    >
                                        {munList && munList.map((data, index) => {
                                            return <option value={data.name}>{data.name}</option>
                                        })}

                                    </select>
                                </div>
                            </Row>
                            {errors.fullName && <p className="form-error">{errors.fullName.message ? errors.fullName.message : "Your Name is Required"}</p>}
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input
                                required
                                placeholder="Address"
                                className="form-control"
                                name="address1"
                                type="text"
                                ref={register({ required: true })}
                                onChange={(e) => {
                                    dispatch(updateAdmissionData('address1', e.currentTarget.value))
                                }}
                            />
                            {errors.address1 && <p className="form-error">{errors.address1.message ? errors.address1.message : "Your Address is Required"}</p>}
                        </div>
                        <div className="form-group">
                            <label>Temporary Address</label>
                            <input
                                placeholder="Temporary Address"
                                className="form-control"
                                name="address2"
                                type="text"
                                ref={register()}
                                onChange={(e) => {
                                    dispatch(updateAdmissionData('address2', e.currentTarget.value))
                                }}
                            />
                            {errors.address2 && <p className="form-error">{errors.address2.message ? errors.address2.message : "Your Name is Required"}</p>}
                        </div>
                        <div className="form-group">
                            <label>Phone Number</label>
                            <input
                                required
                                placeholder="Your Phone"
                                className="form-control"
                                name="phone"
                                type="text"
                                pattern='\d{10}'
                                ref={register({ required: true })}
                                onChange={(e) => {
                                    dispatch(updateAdmissionData('phone', e.currentTarget.value))
                                }}
                            />
                            {errors.phone && <p className="form-error">{errors.phone.message ? errors.phone.message : "Your Phone Number is Required"}</p>}
                        </div>
                        <div className="form-group">
                            <label>Email Address</label>
                            <input
                                required
                                placeholder="Your Email"
                                className="form-control"
                                name="email"
                                type="email"
                                ref={register({ required: true })}
                                onChange={(e) => {
                                    dispatch(updateAdmissionData('email', e.currentTarget.value))
                                }}
                            />
                            {errors.email && <p className="form-error">{errors.email.message ? errors.email.message : "Your Email Address is Required"}</p>}
                        </div>
                        <button
                            type="submit"
                            className="custom-button mt-3 button-primary button-solid"
                        >Next</button>
                    </form>
                </Col>
            </Row>
        </React.Fragment>
    )
}
const StepFour = props => {
    const dispatch = useDispatch();
    const { register, handleSubmit, setError, errors } = useForm({ mode: 'onBlur' });
    const admission = useSelector(state => state.Admission)


    const onSubmit = (value) => {
        console.log(value);
        dispatch(updateAdmissionData('academicHistory', value))
        // props.onClick(1)
        props.onSubmit(value);
    }

    return (
        <React.Fragment>
            <Row className="justify-content-center">

                <Col lg={8}>
                    <div className="d-block py-3 text-bold"><h5 className="text-bold">Academic Details</h5></div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <hr />
                        <Row className="justify-content-between m-0">
                            <Col lg={4}>
                                <h6 className="text-uppercase text-bold">School Details</h6>
                            </Col>

                            <Col lg={4}>
                                <div className="form-group">
                                    <label>School Name</label>
                                    <input
                                        required
                                        placeholder="School Name"
                                        className="form-control"
                                        name="schoolName"
                                        type="text"
                                        ref={register({ required: true })}
                                    />
                                    {errors.schoolName && <p className="form-error">{errors.schoolName.message ? errors.schoolName.message : "School Name is required"}</p>}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>GPA/Percentage</label>
                                    <input
                                        required
                                        placeholder="GPA/Percentage"
                                        className="form-control"
                                        name="schoolGPA"
                                        type="number"
                                        step='0.01'
                                        ref={register({ required: true })}
                                    />
                                    {errors.schoolGPA && <p className="form-error">{errors.schoolGPA.message ? errors.schoolGPA.message : "School GPA is Required"}</p>}
                                </div>
                            </Col>
                        </Row>
                        {(admission.applicationLevel == 2 || admission.applicationLevel == 3) &&
                            <React.Fragment>
                                <hr />
                                <Row className="justify-content-between  m-0">
                                    <Col lg={4}>
                                        <h6 className="text-uppercase text-bold">+2 (Intermediate) Details</h6>
                                    </Col>

                                    <Col lg={4}>
                                        <div className="form-group">
                                            <label>College Name</label>
                                            <input
                                                required
                                                placeholder="College Name"
                                                className="form-control"
                                                name="collegeName"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            {errors.collegeName && <p className="form-error">{errors.collegeName.message ? errors.collegeName.message : "College Name is required"}</p>}
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="form-group">
                                            <label>GPA/Percentage</label>
                                            <input
                                                required
                                                placeholder="GPA/Percentage"
                                                className="form-control"
                                                name="collegeGPA"
                                                type="number"
                                                step='0.01'
                                                ref={register({ required: true })}
                                            />
                                            {errors.collegeGPA && <p className="form-error">{errors.collegeGPA.message ? errors.collegeGPA.message : "College GPA is Required"}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        }
                        {admission.applicationLevel == 3 &&
                            <React.Fragment>
                                <hr />
                                <Row className="justify-content-between  m-0">
                                    <Col lg={4}>
                                        <h6 className="text-uppercase text-bold">Bachelor Details</h6>
                                    </Col>

                                    <Col lg={4}>
                                        <div className="form-group">
                                            <label>Bachelor College Name</label>
                                            <input
                                                required
                                                placeholder="College Name"
                                                className="form-control"
                                                name="bachelorName"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            {errors.bachelorName && <p className="form-error">{errors.bachelorName.message ? errors.bachelorName.message : "College Name is required"}</p>}
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="form-group">
                                            <label>GPA/Percentage</label>
                                            <input
                                                required
                                                placeholder="GPA/Percentage"
                                                className="form-control"
                                                name="bachelorGPA"
                                                type="number"
                                                step='0.01'
                                                ref={register({ required: true })}
                                            />
                                            {errors.bachelorGPA && <p className="form-error">{errors.bachelorGPA.message ? errors.bachelorGPA.message : "College GPA is Required"}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        }


                        <button
                            type="submit"
                            className="custom-button mt-3 button-primary button-solid"
                        >Submit</button>
                    </form>
                </Col>
            </Row>
        </React.Fragment>
    )
}


const Admission = props => {
    const [formStep, setFormStep] = useState(1);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
    const admission = useSelector(state => state.Admission);
    const onChangeStep = (step) => {
        setFormStep(step);
    }
    const onApplicationSubmit = async value => {
        let admissionData = {
            fullName: admission.fullName,
            phone: admission.phone,
            courseName: admission.course,
            // dateOfBirth: admission.dob,
            gender: admission.gender,
            address: admission.address1,
            address1: admission.address2,
            email: admission.email,
            schoolDetails: (value.schoolName || 'N/A') + ' - ' + (value.schoolGPA || 'N/A'),
            collegeDetails: (value.collegeName || 'N/A') + ' - ' + (value.collegeGPA || 'N/A'),
            bachelorDetails: (value.bachelorName || 'N/A') + ' - ' + (value.bachelorGPA || 'N/A'),
        }
        setModal(true);
        setLoading(true);
        console.log(admissionData)
        await requestAdmission(admissionData).then((data) => {
            setLoading(false);
            setError(false);
        })
            .catch(err => {
                setError(err.response.data);
                setLoading(false);
            })
        setTimeout(() => {
        }, 3000)
    }
    const formStepHandler = (step) => {
        switch (step) {
            case 1:
                return <StepOne onClick={(e) => { onChangeStep(e) }} />
            case 2:
                return <StepTwo onClick={(e) => { onChangeStep(e) }} />
            case 3:
                return <StepThree onClick={(e) => { onChangeStep(e) }} />
            case 4:
                return <StepFour onSubmit={e => { onApplicationSubmit(e) }} onClick={(e) => { onChangeStep(e) }} />
            default:
                return <StepOne onClick={(e) => { onChangeStep(e) }} />
        }
    }
    return (
        <div className="admission-page mb-5">
            <Modal
                show={modal}
                centered
                onHide={() => {
                    setModal(false);
                    setFormStep(1)
                    history.push('/')
                }}
            >
                {!loading &&
                    <Modal.Header closeButton>

                    </Modal.Header>
                }
                <Modal.Body>
                    {loading ? (
                        <Loading className="py-5" text="We are recording your application" />
                    ) : (
                            <div className="py-5">
                                {!error ? (
                                    <div className="success-response">
                                        <CompleteIcon />

                                        <h5 className="mt-3 text-bold">Congratulations!!</h5>
                                        <p className="text-center px-4">We have received your application. One of our representatives will get back to your very soon</p>
                                        <h6 className="mt-4 text-bold">Thank you.</h6>
                                    </div>
                                ) : (
                                        <div className="success-response">
                                            <ErrorIcon fill="red" width="50px" />
                                            <h3 className="mt-3 text-bold" style={{ color: 'red' }}>Error!!</h3>
                                            <p className="text-center px-4">Something Went Wrong</p>
                                            <h6 className="mt-4 text-bold">Please Try Again Later.</h6>
                                        </div>
                                    )}

                            </div>
                        )}

                </Modal.Body>
            </Modal>
            <div className="title-section">
                <Container className="mt-n5 pt-5">
                    <Row className="justify-content-center mb-2 mx-0">
                        <Col lg={9} className="text-center">
                            <h3 className="text-uppercase text-center">Admission</h3>
                            <hr style={{ borderColor: 'white' }} />
                            <Badge variant="primary" className="py-2 px-4 mt-3" style={{ backgroundColor: 'orange' }}>Admission Open</Badge>
                        </Col>
                    </Row>
                    <StepForm currentStep={formStep} />

                </Container>

            </div>
            <div className="form-section mt-5">

                <Container>
                    <Row className="pb-5">
                        <Col>
                            <div className="form-wrapper px-4 py-5">
                                {
                                    formStepHandler(formStep)
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Admission;