import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeAdvertisements } from '../../api/api';
import { fetchAdvertisementsSuccess, fetchAdvertisementsFailure } from '../actions/Advertisement';
import { GET_ADVERTISEMENT } from '../constants';

const getAdvertisementsAsync = async (params) => await takeAdvertisements(params);

function* getAdvertisements({payload}) {
    const {params} = payload;

    try {
        const teams = yield call(getAdvertisementsAsync, params);
        if(teams){
            yield put(fetchAdvertisementsSuccess(teams.data))
        }
        else{
            yield put(fetchAdvertisementsFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchAdvertisementsFailure(error));
    }
}

function* watchGetAdvertisements(){
    yield takeEvery(GET_ADVERTISEMENT, getAdvertisements);
}

export default function* advertisementSaga(){
    yield all([
        fork(watchGetAdvertisements)
    ])
}