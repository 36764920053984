import {call, takeEvery, put, fork, all} from 'redux-saga/effects';
import { takeOrganizationDetails } from '../../api/api';
import { fetchOrganizationDetailsSuccess, fetchOrganizationDetailsFailure } from '../actions/organizationInfo';
import { GET_ORGANIZATION_DETAILS } from '../constants';

const getOrganizationDetailsAsync = async () => await takeOrganizationDetails();

function* getOrganizationDetails({payload}) {
    try {
        const info = yield call(getOrganizationDetailsAsync)
        if(info){
            yield put(fetchOrganizationDetailsSuccess(info.data));
        }
        else{
            yield put(fetchOrganizationDetailsFailure("Something Went Wrong!"));
        }
    } catch (error) {
        yield put(fetchOrganizationDetailsFailure(error))
    }
}

function* watchGetOrganizationDetails(){
    yield takeEvery(GET_ORGANIZATION_DETAILS, getOrganizationDetails)
}

export default function* organizationSaga(){
    yield all([
        fork(watchGetOrganizationDetails)
    ])
}